import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ProgressButton from "components/ProgressButton";
import BackButton from "components/BackButton";
import { RadioGroup } from "@headlessui/react";
import MyselfDeactivated from "@img/dateType/myselfDeactivated.svg";
import MyselfActivated from "@img/dateType/myselfActivated.svg";
import PollDeactivated from "@img/dateType/pollDeactivated.svg";
import PollActivated from "@img/dateType/pollActivated.svg";

export default function DateType() {
  const [type, setType] = useState(null);
  const location = useLocation();
  const { id, title, description, address } = location.state;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-col justify-between h-screen">
        <header>
          <Navbar
            variant={"green"}
            navItems={[
              { name: "Create Event", to: "/create" },
              { name: "My Events", to: "/my-events" },
            ]}
          />
          <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
            <div className="mx-auto w-max">
              <BackButton />
            </div>
            <div className="col-span-3 mx-auto text-center">
              <h1 className="text-center text-green-500">Date Select Method</h1>
              <p>How should the date/time of your event be decided?</p>
            </div>

            <div className="invisible"></div>
          </div>
        </header>
        <main className="flex flex-col justify-between h-full mb-auto">
          <div className="mx-auto mt-10 mb-auto w-max">
            <RadioGroup value={type} onChange={setType}>
              <div className="space-y-12">
                <RadioGroup.Option value={0}>
                  {({ checked }) => (
                    <img
                      src={checked ? MyselfActivated : MyselfDeactivated}
                      alt="Myself"
                    />
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value={1}>
                  {({ checked }) => (
                    <img
                      src={checked ? PollActivated : PollDeactivated}
                      alt="Myself"
                    />
                  )}
                </RadioGroup.Option>
              </div>
            </RadioGroup>
          </div>
          <div className="mx-auto mt-10 mb-12 w-max">
            <ProgressButton
              enabled={type !== null}
              text="Continue"
              to={`/${type === 0 ? "single" : "multi"}DateSelect`}
              state={{
                id: id,
                title: title,
                description: description,
                address: address,
              }}
            />
          </div>
        </main>
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
