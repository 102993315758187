import selectData from "./selectData";

import "@fortawesome/fontawesome-free/css/all.css";

import { db } from "./firebaseConfig";

// Import the functions you need from the SDKs you need
import { ref, set } from "firebase/database";

export default function changeEvent(
  eventID,
  newEventName,
  newEventLocation,
  newEventDesc
) {
  return new Promise(function (resolve, reject) {
    selectData(eventID).then(function (event) {
      if (event !== null) {
        event.title = newEventName;
        event.location = newEventLocation;
        event.description = newEventDesc;
        set(ref(db, "Events/" + eventID), event);
        try {
          resolve(true);
        } catch (e) {
          reject(e);
        }
      }
    });
  });
}
