import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import ProgressButton from "components/ProgressButton";
import BackButton from "components/BackButton";
import DateItem from "components/DateItem";
import setPotentialDates from "scripts/setPotentialDates";

export default function ResetDateDriverMulti() {
  const [value, setValue] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [showError, setShowError] = useState(false);
  let { id } = useParams();

  const removeDateItem = (date) => {
    setDates(dates.filter((item) => item !== date));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-col justify-between h-screen">
        <header>
          <Navbar
            variant={"green"}
            navItems={[
              { name: "Create Event", to: "/create" },
              { name: "My Events", to: "/my-events" },
            ]}
          />
          <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
            <div className="mx-auto w-max">
              <BackButton />
            </div>
            <div>
              <h1 className="text-center text-green-500">
                Select Possible Dates/Times
              </h1>
              <p className="mx-auto w-max">
                Add potential dates/times for your attendees to vote on.
              </p>
            </div>

            <div className="invisible"></div>
          </div>
        </header>
        <main className="mb-auto">
          <div className="flex items-center mx-auto mt-10 space-x-4 w-max">
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"
              value={value}
              minDateTime={new Date()}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
            <button
              className="px-2 py-1 text-xl text-white transition-all bg-green-500 rounded-md hover:bg-green-600"
              onClick={() => {
                if (dates.indexOf(value) === -1) {
                  let temp = dates.slice();
                  temp.push(value);
                  setDates(temp.sort((d1, d2) => d1 - d2));
                  setShowError(false);
                } else {
                  setShowError(true);
                }
              }}
            >
              Add <i className="fas fa-plus"></i>
            </button>
          </div>
          <div
            className={`mx-auto my-1 w-max text-lg font-bold text-red-500 transition-all ${
              showError ? "opacity-100" : "opacity-0"
            }`}
          >
            Date is already in list
          </div>
          <div className="w-full h-64 max-w-md mx-auto my-6 space-y-2 overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
            {dates.map((item, i) => (
              <DateItem date={item} onDelete={removeDateItem} />
            ))}
          </div>
          <div className="mx-auto mt-10 w-max">
            <ProgressButton
              onClick={() => {
                setPotentialDates({ id }.id, { dates }.dates);
              }}
              enabled={dates.length > 0}
              text="Continue"
              to={`/event/${id}`}
            />
          </div>
        </main>
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
