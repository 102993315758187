import getUser from "./getUser";
import { db, firebaseConfig } from "./firebaseConfig";
import { ref, set } from "firebase/database";
import { initializeApp } from "firebase/app";
import genIDEvent from "scripts/genIDEvent";

export default function createEvent(
  paramID,
  paramTitle,
  paramDescription,
  paramLocation
) {
  let tmpDate = new Date().getTime();
  let tmpID = genIDEvent();
  initializeApp(firebaseConfig);
  class Event {
    constructor(ID, Title, Description, Location, Date) {
      this.id = ID;
      this.title = Title;
      this.description = Description;
      this.location = Location;
      this.date = Date;
      this.members = [];
      this.posts = [];
      this.potentialDates = [""];
    }
  }
  getUser().then(function (user) {
    var obj = new Event(
      paramID,
      paramTitle,
      paramDescription,
      paramLocation,
      "TBD"
    );
    obj.members.push([user.uid, 2]);
    obj.posts.push("")
    obj.posts.push({
      id: tmpID,
      title: "New Event",
      text: "Welcome to your new event!",
      time: tmpDate,
      link: `post/${tmpID}`,
      replies: [""],
      user: {
        id: null,
        firstName: "Groupi",
        lastName: "Team",
        role: 0,
        link: "/about",
        img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
      }
    }
  )
    set(ref(db, "Events/" + obj.id.toString()), obj);
  });
}
