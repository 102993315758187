//import "./index.css";
//import "@fortawesome/fontawesome-free/css/all.css";
//import Home from "@pages/Home";
//import reportWebVitals from "./reportWebVitals";
//import Test from "@pages/Test";
//import { Transition } from "@headlessui/react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import {getDatabase, ref, set} from 'https://www.gstatic.com/firebasejs/9.6.8/firebase-database.js';
import { getDatabase, ref, onValue } from "firebase/database";
import { firebaseConfig } from "./firebaseConfig";

//Opens firebase entry for user at ID
export default function selectDataUser(ID) {
  initializeApp(firebaseConfig);
  const dbref = ref(getDatabase(), "Users/" + ID);
  return new Promise(function (resolve, reject) {
    try {
      onValue(dbref, (snapshot) => {
        const data = snapshot.val();
        resolve(data);
      });
    } catch (e) {
      reject(e);
    }
  });
}
