import selectDataUser from "./selectDataUser";
import getUser from "./getUser";
import addNewUser from "scripts/addUser";
import { ref, getDownloadURL, getStorage } from "firebase/storage";

export default function userBranch(to) {
  return new Promise(function (resolve, reject) {
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (user) {
        if (user != null) {
          resolve(to);
        } else {
          getDownloadURL(ref(getStorage(), "images/defaultPfp.png")).then((url) => {
            addNewUser("Groupi", "Member", url);
            resolve("/welcome");
          });
        }
      });
    });
  });
}
