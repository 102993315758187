import Notification from "./Notification";
import selectDataUser from "scripts/selectDataUser";
import selectData from "scripts/selectData";
import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import deleteNotif from "scripts/deleteNotif";
import getUser from "scripts/getUser";
function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export default function NotificationFactory({ paramNotif }) {
  const [notifPFP, setNotifPFP] = React.useState("");
  const [notifName, setNotifName] = React.useState("");
  const [notifEvent, setNotifEvent] = React.useState("");
  const [currentUser, setCurrentUser] = React.useState();

  getUser().then(function (user) {
    if (user === null) {
    } else {
      //setUserID(user.uid);

      selectDataUser(user.uid).then(function (x) {
        setCurrentUser(user.uid);
      });
    }
  });

  
  selectDataUser(paramNotif.sentID).then(function (x) {
    setNotifPFP(x.img);
    setNotifName(x.firstName + " " + x.lastName);
  });
  
  
  
  selectData(paramNotif.event).then(function (x) {
    setNotifEvent(x.title);
  });

 
 
  
  if (paramNotif.type === "event_join") {
    return (
      <div>
        <Menu.Item>
        <Link to={paramNotif.link} onClick={() => deleteNotif(currentUser, paramNotif.event, paramNotif.time)}>
            <Notification
              message={
                <p className="text-xs">
                  <strong>{notifName}</strong> joined{" "}
                  <strong>{notifEvent}</strong>
                </p>
              }
              image={notifPFP}
              timeSince={timeSince(paramNotif.time)}
              isUnread={true}
            />
          </Link>
        </Menu.Item>
      </div>
    );
  }
 
  if (paramNotif.type === "new_post") {
    return (
      <div>
        <Menu.Item>
          <Link to={paramNotif.link} onClick={() => deleteNotif(currentUser, paramNotif.event, paramNotif.time)}>
            <Notification
              message={
                <p className="text-xs">
                  <strong>{notifName}</strong> made a new post in{" "}
                  <strong>{notifEvent}</strong>
                </p>
              }
              image={notifPFP}
              timeSince={timeSince(paramNotif.time)}
              isUnread={true}
            />
          </Link>
        </Menu.Item>
      </div>
    );
  }

  if (paramNotif.type === "date_change") {
    return (
      <div>
        <Menu.Item>
        <Link to={paramNotif.link} onClick={() => deleteNotif(currentUser, paramNotif.event, paramNotif.time)}>
            <Notification
              message={
                <p className="text-xs">
                  <strong>{notifName}</strong> changed the date of {" "}
                  <strong>{notifEvent}</strong>
                </p>
              }
              image={notifPFP}
              timeSince={timeSince(paramNotif.time)}
              isUnread={true}
            />
          </Link>
        </Menu.Item>
      </div>
    );
  }

  if (paramNotif.type === "location_change") {
    return (
      <div>
        <Menu.Item>
        <Link to={paramNotif.link} onClick={() => deleteNotif(currentUser, paramNotif.event, paramNotif.time)}>
            <Notification
              message={
                <p className="text-xs">
                  <strong>{notifName}</strong> changed the location of {" "}
                  <strong>{notifEvent}</strong>
                </p>
              }
              image={notifPFP}
              timeSince={timeSince(paramNotif.time)}
              isUnread={true}
            />
          </Link>
        </Menu.Item>
      </div>
    );
  }

    if (paramNotif.type === "moderator_add") {
    return (
      <div>
        <Menu.Item>
        <Link to={paramNotif.link} onClick={() => deleteNotif(currentUser, paramNotif.event, paramNotif.time)}>
            <Notification
              message={
                <p className="text-xs">
                  <strong>{notifName}</strong> has made you a moderator of {" "}
                  <strong>{notifEvent}</strong>
                </p>
              }
              image={notifPFP}
              timeSince={timeSince(paramNotif.time)}
              isUnread={true}
            />
          </Link>
        </Menu.Item>
      </div>
    );
  }

  return(
    <div></div>
  );


 }
