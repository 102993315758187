import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";

export default function RequireAuth() {
  const [isLoggedIn, setIsLoggedin] = React.useState(null);
  const location = useLocation();
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null) {
        setIsLoggedin(true);
      } else {
        setIsLoggedin(false);
      }
    });
  });
  return isLoggedIn !== null ? (
    isLoggedIn ? (
      <Navigate to="/" state={{ from: location }} replace />
    ) : (
      <Outlet />
    )
  ) : (
    <LoadingSpinner />
  );
}
