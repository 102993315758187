import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import ProgressButton from "components/ProgressButton";
import setDate from "scripts/setDate";
import BackButton from "components/BackButton";
import createEvent from "scripts/createEvent";
import { db } from "scripts/firebaseConfig";
import { ref, set } from "firebase/database";
import getUser from "scripts/getUser";
import selectDataUser from "scripts/selectDataUser";

export default function SingleDateSelect() {
  const [value, setValue] = useState(new Date());
  const location = useLocation();
  const { id, title, description, address } = location.state;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-col justify-between h-screen">
        <header>
          <Navbar
            variant={"green"}
            navItems={[
              { name: "Create Event", to: "/create" },
              { name: "My Events", to: "/my-events" },
            ]}
          />
          <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
            <div className="mx-auto w-max">
              <BackButton />
            </div>
            <div className="col-span-3 mx-auto text-center">
              <h1 className="text-green-500">Select Event Date/Time</h1>
              <p>Select the date and time for your event.</p>
            </div>
            <div className="invisible"></div>
          </div>
        </header>
        <main className="flex flex-col justify-between h-full mb-auto">
          <div className="mx-auto mt-10 mb-80 w-max">
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"
              value={value}
              minDateTime={new Date()}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          </div>
          <div className="mx-auto mb-12 w-max">
            <ProgressButton
              onClick={() => {
                //console.log({id}.id)
                createEvent(id, title, description, address);
                getUser().then(function (userAuth) {
                  selectDataUser(userAuth.uid).then(function (user) {
                    user.events[user.events.length] = { id }.id;
                    set(ref(db, "Users/" + user.id), user);
                  });
                });
                setDate({ id }.id, { value }.value);
              }}
              enabled={true}
              text="Continue"
              to={`/invite/${id}`}
            />
          </div>
        </main>
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
