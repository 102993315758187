import "@fortawesome/fontawesome-free/css/all.css";
import { db } from "./firebaseConfig";
import selectData from "./selectData";
import {set, ref} from "firebase/database"


export default function setPotentialDates(eventID, DateArr) {
  selectData(eventID).then(function (event){
    event.date = "TBD";
    var tmpArr = [];
    var tmpArr2 = [];
    var tmpArr3 = [];
    DateArr.forEach(element => {
        tmpArr.push(element.getTime())
    });
    event.potentialDates = tmpArr;
    tmpArr.forEach(element => {
        tmpArr2.push([element, true]);
        tmpArr3.push([element]);
    })
    event.members.forEach(element => {
        if (element[1] === 2){
            element[2] = tmpArr2;
        }
        else{
            element[2] = tmpArr3;
        }
    })
    set(ref(db, "Events/" + event.id.toString()), event);
  })
}
