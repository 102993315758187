import selectDataUser from "scripts/selectDataUser";
import addUserToEvent from "scripts/addUserToEvent";
import selectData from "scripts/selectData";
import getUser from "scripts/getUser";

export default function inviteUser(paramEventID, paramUserID) {
  return new Promise(function (resolve, reject) {
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (selectedUser) {
        //setName(selectedUser.firstName);
      });
      if (user !== null) {
        //add logged-in user to event
        selectData(paramEventID).then(function (userEvent) {
          // setEventName(userEvent.title);
          //console.log("eventName is: " + eventName);
        });
        //addUserToEvent(paramID, user.uid);
        addUserToEvent(paramEventID, paramUserID);
        //console.log("User ID: " + paramUserID + "added to " + paramEventID);
      } else {
        //console.log("User not logged in" + user);
        //console.log("navigating");
      }
      //promise here
      try {
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  });
}
