import React from "react";
export default function PollDateItem({ date, onSelect, state, index }) {
  const dateString = date.toLocaleString("default", {
    dateStyle: "long",
  });
  const timeString = date.toLocaleString("default", {
    timeStyle: "short",
  });
  const selectedStyles = "transition-all bg-green-500 text-white hover:bg-green-600 hover:border-green-600";
  const unselectedStyles = "transition-all bg-white text-green-500 hover:bg-black hover:bg-opacity-5";
  let style = !state ? unselectedStyles : selectedStyles
  
  return (
    <button className="mx-auto w-max flex" onClick={() => onSelect(index)}>
      <div className={`mx-auto flex w-full max-w-sm items-center justify-between rounded-md border-2 border-green-500 p-3 ${style}`}>
        <div className="flex items-center space-x-3">
          <span className="text-2xl">{dateString}</span>
          <span>{timeString}</span>
        </div>
      </div>
    </button>
  );
}
