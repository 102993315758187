import react from "react";
import Navbar from "@components/Navbar";
import ProgressButton from "components/ProgressButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Footer from "components/Footer";
import genIDEvent from "scripts/genIDEvent";
import BackButton from "components/BackButton";

export default function CreateEvent() {
  const [id] = react.useState(genIDEvent());
  const [address, setAddress] = react.useState("");
  const handleChange3 = (address) => {
    setAddress(address);
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => setAddress(results[0].formatted_address))
      .catch((error) => console.error("Error", error));
  };

  const [title, setTitle] = react.useState("");
  const [description, setDescription] = react.useState("");
  const handleChange2 = (event) => {
    setDescription(event.target.value);
  };
  const handleChange1 = (event) => {
    setTitle(event.target.value);
  };
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <div className="col-span-3 mx-auto text-center">
            <h1 className="mx-auto text-green-500 w-max">Event Details</h1>
            <p className="mx-auto w-max">Specify the details of your event</p>
          </div>
        </div>
      </header>
      <main className="flex flex-col justify-between h-full mb-auto">
        <div className="w-full max-w-sm px-6 mx-auto mb-auto">
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiTextField-root": { width: "25ch" },
            }}
          >
            <TextField
              fullWidth
              required
              id="standard-required"
              label="Event Title"
              variant="standard"
              margin="normal"
              value={title}
              onChange={handleChange1}
              sx={{
                "& label.Mui-focused": {
                  color: "#13BB7D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#13BB7D",
                },
                "& .MuiInputLabel-root": {
                  color: "#13BB7D",
                  fontSize: "1.125rem",
                  fontFamily: "Lato",
                  lineHeight: "1.75rem",
                },
              }}
            />

            <TextField
              fullWidth
              id="standard"
              label="Details"
              variant="standard"
              margin="normal"
              value={description}
              onChange={handleChange2}
              sx={{
                "& label.Mui-focused": {
                  color: "#13BB7D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#13BB7D",
                },
                "& .MuiInputLabel-root": {
                  color: "#13BB7D",
                  fontSize: "1.125rem",
                  fontFamily: "Lato",
                  lineHeight: "1.75rem",
                },
              }}
            />

            <div className="w-full mt-4">
              <span className="text-lg text-green-500">Location</span>
              <PlacesAutocomplete
                value={address}
                onChange={handleChange3}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "",
                        className:
                          "location-search-input w-full border-b hover:border-black hover:border-b-2 transition-all duration-50 outline-none",
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active bg-green-500 text-white cursor-pointer px-2 py-1 w-full"
                          : "suggestion-item cursor-pointer px-2 py-1 w-full";
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          </Box>
        </div>
        <div className="mx-auto mt-10 mb-12 w-max">
          <ProgressButton
            enabled={title !== ""}
            to={`/dateType`}
            state={{
              id: id,
              title: title,
              description: description,
              address: address,
            }}
            text="Continue"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}
