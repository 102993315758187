import React from "react";
import Navbar from "@components/Navbar";
import ProgressButton from "components/ProgressButton";
import ReactCodeInput from "react-verification-code-input";
import { useState } from "react";
import verifyOTP from "scripts/verifyOTP";
import userBranch from "scripts/userBranch";
import Footer from "components/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "components/BackButton";

export default function Code() {
  const [buttonEnabled, setButtonEnabled] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const { from } = location.state;
  const [nextPage, setNextPage] = useState("");
  const handleChange = (vals) => {
    if (vals.length >= 6) {
      //console.log('complete, ', vals);
      //setButtonEnabled()
    } else if (vals.length === 0) {
      //console.log('empty, ', vals);
    }
  };
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="col-span-3 mx-auto text-center text-green-500">
            Enter Your Code
          </h1>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <div className="mx-auto w-max">
          <ReactCodeInput
            className="custom-class"
            onChange={handleChange}
            //code which excecutes after OTP verification code is correctly verified
            onComplete={(val) => {
              verifyOTP(val).then(function (flag) {
                setButtonEnabled(flag);
                if (flag === true) {
                  userBranch(from).then(function (toLink) {
                    setNextPage(toLink);
                    navigate(toLink, { replace: true, state: { from: from } });
                  });
                }
              });
            }}
          />
        </div>
        <div className="mx-auto w-max">
          <ProgressButton
            enabled={buttonEnabled}
            to={nextPage}
            state={{ replace: true, from: from }}
            text="Continue"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}
