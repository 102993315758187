import selectDataUser from "scripts/selectDataUser"
import selectData from "scripts/selectData"

class EventPageUser{
    constructor(firstName, lastName, role, img, id, link){
        this.firstName = firstName;
        this.lastName = lastName;
        this.role = role;
        this.img = img;
        this.id = id;
        this.link = link
    }
}
class UserObject{
    constructor(arr){
        this.users = arr
    }
}

export default async function getUsersForEventPage(id){
    return new Promise(function (resolve, reject){
        selectData(id).then(function (event){
            var userArr = []
            //console.log(event.members)
            event.members.forEach(element => {
                selectDataUser(element[0]).then(function (user){
                    //console.log(user);
                    //console.log("Storing: " + user.firstName + " " + user.lastName)
                    userArr.push(new EventPageUser(user.firstName, user.lastName, element[1], user.img, user.id, `/profile/${user.id}`))

                })
            });
            //console.log(userArr)
            resolve(new UserObject(userArr));
        })
    })
}