import { Link } from "react-router-dom";
import React from "react";
import selectData from "scripts/selectData";
import getDate from "scripts/getDate";
import getTime from "scripts/getTime";

export default function EventCard({ id }) {
    const [title, setTitle] = React.useState("title");
    const [location, setLocation] = React.useState("location");
    const [date, setDate] = React.useState("date");
    const [time, setTime] = React.useState("time");
    React.useEffect(() => {
        selectData(id).then(function (tmp) {
          setTitle(tmp.title)
          setLocation(tmp.location)
          //set Date
          if(tmp.date !== "TBD"){
            setDate(getDate(tmp.date));
          }
          else{
            setDate(tmp.date)
          }
          //set Time
          if(tmp.date !== "TBD"){
            setTime(getTime(tmp.date));
          }
          else{
            setTime(tmp.date)
          }
        });
      }, [id]);  
  return (
    <Link to={`/event/${id}`}>
      <div className="hover:bg-black hover:bg-opacity-5 transition-all mx-auto space-x-3 flex items-center w-full max-w-xs flex-row justify-evenly rounded-md bg-gray-50 bg-opacity-5 p-2 shadow-md md:w-full md:max-w-md lg:max-w-xl">
        <div className="mt-2">
          <span className="text-xl">{title}</span>
        </div>
        <div className="space-y-1">
          <span className="text-sm"><i className="fas fa-clock mr-1"></i>{date} {time}</span>
          <p className="flex-grow text-sm text-gray-700"><i className="fas fa-map-marker-alt mr-1"></i>{location}</p>
        </div>
      </div>
    </Link>
  );
}
