import { Navigate, Outlet, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import LoadingSpinnerFull from "./LoadingSpinnerFull";
import selectData from "scripts/selectData";

export default function RequireOrganizer() {
  const [isOrganizer, setisOrganizer] = React.useState(null);
  const { id } = useParams();
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null) {
        selectData(id).then(function (event) {
          if (event !== null) {
            let temp = false;
            for (const item of event.members) {
              if (item[0] === user.uid) {
                if (item[1] === 2) {
                  temp = true;
                }
              }
            }
            if (temp) {
              setisOrganizer(true);
            } else {
              setisOrganizer(false);
            }
          } else {
            setisOrganizer(false);
          }
        });
      } else {
        setisOrganizer(false);
      }
    });
  });
  return isOrganizer !== null ? (
    isOrganizer ? (
      <Outlet />
    ) : (
      <Navigate to="/noAccess" replace />
    )
  ) : (
    <LoadingSpinnerFull />
  );
}
