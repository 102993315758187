import selectData from "scripts/selectData";
import { db } from "scripts/firebaseConfig";
import { ref, set } from "firebase/database";

export default function addReply(id, PostID, reply, user) {
  return new Promise(function (resolve, reject){
    selectData(id).then(function (event) {
      event.posts.forEach((element) => {
        if (element.id === Number.parseInt(PostID, 10)) {
          element.replies.push({
              text: reply,
              time: (new Date()).getTime(),
              user: user
          });
          set(ref(db, "Events/" + id.toString()), event);
          try{
            resolve(true);
          } catch (e) {
            reject(e);
          }
        }
      });
    });
  })
  }