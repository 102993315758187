import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="mx-auto space-y-2 mt-36 w-max">
      <h1 className="text-6xl text-green-500">Uh oh :(</h1>
      <p className="text-xl">Sorry, we couldn't find that page for you.</p>
      <button
        onClick={() => navigate(-1)}
        className="px-3 py-2 space-x-2 text-green-500 transition-all border-2 border-green-500 rounded-md w-max hover:bg-green-500 hover:text-white"
      >
        <span className="text-xl font-bold leading-none">Go Back</span>
      </button>
    </div>
  );
}
