import leaveEvent from "./leaveEvent";
import selectData from "./selectData";
import removeEventFromDatabase from "./removeEventFromDatabase";

export default function createEvent(eventID, userID) {

    selectData(eventID).then(function (event){
        event.members.forEach(function (item, index, object) {
            leaveEvent(eventID, item[0]);
        })
        removeEventFromDatabase(eventID);
    });
}
