import { db } from "./firebaseConfig";
import { ref, set } from "firebase/database";
import selectData from "./selectData";

export default function removeUserFromEvent(eventID, userID) {
    selectData(eventID).then(function (event){
        event.members.forEach(function (item, index, object) {
            if(item[0].toString() === userID.toString()){
                object.splice(index, 1);
                set(ref(db, "Events/" + event.id.toString()), event);
            }
        });
    });
}