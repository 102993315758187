import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import react from "react";
import Modal from "./Modal";
import leaveEvent from "scripts/leaveEvent";
import promoteUser from "scripts/promoteUser";

export default function UserIcon({
  user,
  currentUid,
  currentRole,
  eventID,
  update,
}) {
  const [showKickModal, setShowKickModal] = react.useState(false);
  const [showPromoteModal, setShowPromoteModal] = react.useState(false);
  const [showDemoteModal, setShowDemoteModal] = react.useState(false);
  let roles = ["Attendee", "Moderator", "Organizer"];
  let badge = "";
  if (user.role === 2) {
    badge = "fa-crown";
  }
  if (user.role === 1) {
    badge = "fa-star";
  }
  let badgeId = Math.random() + "-badge";
  return (
    <div className="relative">
      {showPromoteModal && (
        <Modal
          title="Promote User"
          description={
            <span>
              Are you sure you want to promote this user to moderator?
            </span>
          }
          button2Text="Promote"
          button1Text="Cancel"
          onClick2={() => {
            promoteUser(eventID, user.id, 1).then((r) => {
              if (r) {
                update();
              }
            });
          }}
          onClick1={() => {
            setShowPromoteModal(false);
          }}
          open={showPromoteModal}
          setOpen={setShowPromoteModal}
        />
      )}
      {showKickModal && (
        <Modal
          title="Kick User"
          description={
            <span>Are you sure you want to kick this user from the event?</span>
          }
          button2Text="Kick"
          button1Text="Cancel"
          onClick2={() => {
            leaveEvent(eventID, user.id).then((r) => {
              if (r) {
                update();
              }
            });
          }}
          onClick1={() => {
            setShowKickModal(false);
          }}
          open={showKickModal}
          setOpen={setShowKickModal}
        />
      )}

      {showDemoteModal && (
        <Modal
          title="Demote User"
          description={
            <span>Are you sure you want to demote this user to member?</span>
          }
          button2Text="Demote"
          button1Text="Cancel"
          onClick2={() => {
            promoteUser(eventID, user.id, 0).then((r) => {
              if (r) {
                update();
              }
            });
          }}
          onClick1={() => {
            setShowDemoteModal(false);
          }}
          open={showDemoteModal}
          setOpen={setShowDemoteModal}
        />
      )}
      <Menu>
        <Menu.Button>
          <div className="relative w-max">
            <img
              className="object-cover w-10 h-10 rounded-full"
              src={user.img}
              alt="user icon"
            />
            <i
              className={`fas absolute text-green-500 ${badge} -top-1 -right-1 rotate-12 ${
                user.role === 0 ? "hidden" : ""
              }`}
              data-tip
              data-for={badgeId}
            />
            <ReactTooltip id={badgeId} type="dark" effect="solid">
              <span>{roles[user.role]}</span>
            </ReactTooltip>
          </div>
        </Menu.Button>
        <Transition
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute left-0 z-50 py-3 mt-2 origin-top-left bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/profile/${user.id}`}
                  className={`mx-2 block rounded-md px-2 py-1  text-sm ${
                    active
                      ? "hover:bg-green-500 hover:text-white"
                      : "text-gray-700"
                  } `}
                >
                  User Profile
                </Link>
              )}
            </Menu.Item>
            {currentRole === 2 && user.role === 0 && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={``}
                    onClick={() => {
                      setShowPromoteModal(true);
                    }}
                    className={`mx-2 block rounded-md px-2 py-1  text-sm ${
                      active
                        ? "hover:bg-green-500 hover:text-white"
                        : "text-gray-700"
                    } `}
                  >
                    Promote to moderator
                  </Link>
                )}
              </Menu.Item>
            )}
            {currentRole === 2 && user.role === 1 && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={``}
                    onClick={() => {
                      setShowDemoteModal(true);
                    }}
                    className={`mx-2 block rounded-md px-2 py-1  text-sm ${
                      active
                        ? "hover:bg-green-500 hover:text-white"
                        : "text-gray-700"
                    } `}
                  >
                    Demote to member
                  </Link>
                )}
              </Menu.Item>
            )}
            {((currentRole === 2 && currentUid !== user.id) ||
              (currentRole === 1 &&
                currentUid !== user.id &&
                user.role === 0)) && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    onClick={() => {
                      setShowKickModal(true);
                    }}
                    to={``}
                    className={`mx-2 block rounded-md px-2 py-1 text-sm ${
                      active
                        ? "hover:bg-red-500 hover:text-white"
                        : "text-red-500"
                    } `}
                  >
                    Kick User
                  </Link>
                )}
              </Menu.Item>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
