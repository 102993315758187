import { Navigate, Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import LoadingSpinnerFull from "./LoadingSpinnerFull";
import selectDataUser from "scripts/selectDataUser";

export default function RequireNewUser() {
  const [isNewUser, setIsNewUser] = React.useState(null);
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null) {
        selectDataUser(user.uid).then(function (u) {
          if (u.newUser === true) {
            setIsNewUser(true);
          } else {
            setIsNewUser(false);
          }
        });
      } else {
        setIsNewUser(false);
      }
    });
  });
  return isNewUser !== null ? (
    isNewUser ? (
      <Outlet />
    ) : (
      <Navigate to="/noAccess" replace />
    )
  ) : (
    <LoadingSpinnerFull />
  );
}
