import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import UserIcon from "./UserIcon";
import deletePost from "scripts/deletePost";

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export default function PostCard({ post, eventID, update, userRole, userID }) {
  const navigate = useNavigate();
  const temp = new Date(post.time);
  //const timeSince = temp.toLocaleString("default", { timeStyle: "short" });
  const tSince = timeSince(temp);
  return (
    <div className="flex flex-col w-3/4 max-w-sm p-2 mx-auto rounded-md shadow-md bg-gray-50 bg-opacity-5 md:w-full md:max-w-md lg:max-w-xl">
      <div className="relative ml-auto w-max">
        {(userID === post.user.id || userRole === 1 || userRole === 2) && (
          <Menu>
            <Menu.Button className="w-6 h-6">
              <i className="text-xl text-gray-700 fas fa-ellipsis"></i>
            </Menu.Button>
            <Transition
              enter="transition ease-out duration-200 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items
                className="absolute right-0 z-50 px-2 py-3 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                {userID === post.user.id && (
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={`block w-full rounded-md px-2 py-1 text-sm ${
                          active
                            ? "hover:bg-green-500 hover:text-white"
                            : "text-gray-700"
                        } `}
                      >
                        <button
                          className="w-full text-left"
                          onClick={() => {
                            navigate(`${post.link}/edit`);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                )}
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`block w-full rounded-md px-2 py-1 text-sm ${
                        active
                          ? "hover:bg-green-500 hover:text-white"
                          : "text-gray-700"
                      } `}
                    >
                      <button
                        className="w-full text-left"
                        onClick={() => {
                          deletePost(
                            eventID,
                            Number.parseInt(post.id, 10)
                          ).then((r) => {
                            if (r) {
                              update();
                            }
                          });
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </div>
      <div className="flex items-center justify-between mb-1">
        <div className="flex items-center mt-2 space-x-3">
          <UserIcon user={post.user} />
          <span>
            {post.user.firstName} {post.user.lastName}{" "}
          </span>
        </div>

        <span className="text-xs text-gray-200">{tSince} ago</span>
      </div>
      <div className="px-2">
        <span className="font-bold">{post.title}</span>
        <p className="flex-grow text-xs text-gray-700">{post.text}</p>
      </div>
      <div className="ml-auto transform w-max">
        <Link to={post.link}>
          <div className="mt-1 mr-1 text-sm text-right transition-all duration-300 group hover:text-green-500">
            View Post
            <i className="ml-1 transition duration-300 transform fas fa-arrow-right group-hover:translate-x-1" />
          </div>
        </Link>
      </div>
    </div>
  );
}
