import removeEventFromUser from "./removeEventFromUser";
import removeUserFromEvent from "./removeUserFromEvent";

export default function leaveEvent(eventID, userID) {
  return new Promise(function (resolve, reject) {
    removeEventFromUser(eventID, userID);
    removeUserFromEvent(eventID, userID);
    try {
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
