export default function Notification({ message, timeSince, image, isUnread }) {
  return (
    <div className="flex items-center p-2 space-x-2 hover:bg-black hover:bg-opacity-5">
      <img className="w-8 h-8 rounded-full" src={image} alt="Profile" />
      <div
        className={`-space-y-1/2 flex flex-grow flex-col ${
          isUnread ? "text-black" : "text-gray-600"
        }`}
      >
        {message}
        <span className="text-xs text-green-500">{timeSince} ago</span>
      </div>
      <div className={`w-3 ${isUnread ? "visible" : "invisible"}`}>
        <div className="w-2 h-2 bg-green-500 rounded-full"></div>
      </div>
    </div>
  );
}
