import { Link } from "react-router-dom";

export default function ProgressButton({ enabled, to, text, onClick, state }) {
  let buttonStyles = !enabled
    ? "bg-gray-50 text-black"
    : "bg-green-500 text-white hover:bg-green-600";
  return (
    <Link
      className={!enabled ? "pointer-events-none" : ""}
      to={to}
      onClick={onClick}
      state={state}
    >
      <div
        className={`w-max rounded-full py-2 px-20 text-4xl font-bold transition-all ${buttonStyles}`}
      >
        {text}
      </div>
    </Link>
  );
}
