import react from "react";
import Navbar from "@components/Navbar";
import ProgressButton from "components/ProgressButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import addNewUser from "scripts/addUser";
import getUser from "scripts/getUser";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Footer from "components/Footer";
import BackButton from "components/BackButton";
import { useLocation } from "react-router-dom";

function isFileImage(file) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  return file && acceptedImageTypes.includes(file["type"]);
}

function uploadBlob(blob) {
  return new Promise(function (resolve, reject) {
    if (isFileImage(blob)) {
      //console.log(blob);
      const storage = getStorage();
      getUser().then(function (user) {
        const storageRef = ref(storage, "images/" + user.uid);
        uploadBytes(storageRef, blob).then((snapshot) => {
          getDownloadURL(ref(storage, "images/" + user.uid)).then((url) => {
            resolve(url);
          });
        });
      });
    } else {
      resolve(
        "https://firebasestorage.googleapis.com/v0/b/groupi-916d6.appspot.com/o/images%2FdefaultPfp.png?alt=media&token=87da0092-0f0d-4953-9c5f-992f08f6000f"
      );
    }
  });
}

export default function NewUser() {
  const [firstName, setFirstName] = react.useState("Groupi");
  const [lastName, setLastName] = react.useState("Member");
  const [image, setImage] = react.useState(null);
  const location = useLocation();
  const { from } = location.state;
  const handleChange2 = (event) => {
    setLastName(event.target.value);
  };
  const handleChange1 = (event) => {
    setFirstName(event.target.value);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImage(img);
    }
  };
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="mx-auto text-green-500 w-max">Welcome to Groupi</h1>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <p className="mx-auto w-max">
          We just need a few bits of info to get you started.
        </p>
        <div className="mx-auto w-max">
          <div className="mx-auto my-5 w-max">
            <h3 className="text-green-500">Profile Image</h3>
            <img
              className="object-cover w-24 h-24 mx-auto my-3 rounded-full"
              src={image != null ? URL.createObjectURL(image) : "logo192.png"}
              alt="profile"
            />
            <input
              className="block w-full px-1 py-1 m-0 text-sm font-normal text-gray-700 transition ease-in-out bg-white rounded form-control"
              type="file"
              name="myImage"
              onChange={onImageChange}
            />
          </div>
          <div className="mx-auto w-max">
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiTextField-root": { width: "25ch" },
              }}
            >
              <TextField
                required
                id="standard-required"
                label="First Name"
                variant="standard"
                margin="normal"
                value={firstName}
                onChange={handleChange1}
                sx={{
                  "& label.Mui-focused": {
                    color: "#13BB7D",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#13BB7D",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#13BB7D",
                    fontSize: "1.125rem",
                    fontFamily: "Lato",
                    lineHeight: "1.75rem",
                  },
                }}
              />
              <TextField
                required
                id="standard"
                label="Last Name"
                variant="standard"
                margin="normal"
                value={lastName}
                onChange={handleChange2}
                sx={{
                  "& label.Mui-focused": {
                    color: "#13BB7D",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#13BB7D",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#13BB7D",
                    fontSize: "1.125rem",
                    fontFamily: "Lato",
                    lineHeight: "1.75rem",
                  },
                }}
              />
            </Box>
          </div>
        </div>
        <div className="mx-auto mt-10 w-max">
          <ProgressButton
            enabled={firstName !== "" && lastName !== ""}
            to={from}
            onClick={() => {
              uploadBlob({ image }.image).then(function (img) {
                getUser().then(function (user) {
                  addNewUser(
                    { firstName }.firstName,
                    { lastName }.lastName,
                    img
                  );
                });
              });
            }}
            text="Done"
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}
