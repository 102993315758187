import { db } from "./firebaseConfig";
import { ref, set } from "firebase/database";
import selectData from "./selectData";
import getUser from "./getUser";
import addNotification from "./addNotification";
export default function promoteUser(eventID, userID, role) {
  return new Promise(function (resolve, reject) {
    selectData(eventID).then(function (event) {
      getUser().then(function (user) {
        addNotification(
          user.uid,
          userID,
          "moderator_add",
          eventID,
          "/event/" + eventID
        );
      });
      event.members.forEach(function (item, index, object) {
        if (item[0].toString() === userID.toString()) {
          item[1] = role;
          set(ref(db, "Events/" + event.id.toString()), event);
        }
      });
    });
    try {
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
