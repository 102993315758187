import { db } from "./firebaseConfig";
import { ref, set } from "firebase/database";
import selectDataUser from "./selectDataUser";

export default function removeEventFromUser(eventID, userID) {
  selectDataUser(userID).then(function (user) {
    user.events.forEach(function (item, index, object) {
      if (item.toString() === eventID.toString()) {
        object.splice(index, 1);
        set(ref(db, "Users/" + user.id.toString() + "/events"), user);
      }
    });
  });
}
