import selectData from "scripts/selectData";
import getUser from "scripts/getUser";
import selectDataUser from "scripts/selectDataUser";
import { db } from "scripts/firebaseConfig";
import { ref, set } from "firebase/database";
import genIDEvent from "scripts/genIDEvent";
import addNotification from "./addNotification";

export default function setPost(id, title, text) {
    return new Promise(function (resolve, reject) {
      let tmpDate = new Date();
      let tmpID = genIDEvent();
      //console.log(title);
      //console.log(text);
      selectData(id).then(function (event) {
        getUser().then(function (userAuth) {
          selectDataUser(userAuth.uid).then(function (user) {
            var tmpRole = 0;
            event.members.forEach((element) => {
              if (user.id === element[0]) {
                tmpRole = element[1];
              }
            });
            event.posts.push({
              id: tmpID,
              title: title,
              text: text,
              time: tmpDate.getTime(),
              link: `post/${tmpID}`,
              replies: [""],
              user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                role: tmpRole,
                link: `/profile/${user.id}`,
                img: 'https://firebasestorage.googleapis.com/v0/b/groupi-916d6.appspot.com/o/images%2FdefaultPfp.png?alt=media&token=87da0092-0f0d-4953-9c5f-992f08f6000f'
              },
            });
            set(ref(db, "Events/" + event.id.toString()), event);
            
            event.members.forEach((member) => {
              //console.log("ADDING NOTIFICATION FROM " + userAuth.uid + " TO " + member[0] + "FROM EVENT: " + id);
              if(member[0] !== userAuth.uid )
              {
                addNotification(userAuth.uid, member[0], "new_post", id, "/event/" + id);
              }
            });
            //addNotification("cpvsiL4LoDOiYthJGZO79SLqiW23", userAuth.uid, "new_post","1000780617950000", "google.com");
            try {
              resolve(true);
            } catch (e) {
              reject(e);
            }
          });
        });
      });
    });
  }