import getUser from "./getUser";
import logoAlt from "@img/brand/logo.svg";

export default function verifyOTP(otp) {
  var myImage = new Image(100, 200);
  myImage.src = { logoAlt };
  return new Promise(function (resolve, reject) {
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          getUser().then(function (user) {
            resolve(true);
          });
        })
        .catch((error) => {
          resolve(false);
        });
    }
  });
}
