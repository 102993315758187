import react from "react";
import TextField from "@mui/material/TextField";
import React from "react";
import setPost from "scripts/setPost";

export default function PostCreation({ id, update }) {
  const [title, setTitle] = react.useState("");
  const [body, setBody] = react.useState("");
  const handleChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const handleChangeBody = (event) => {
    setBody(event.target.value);
  };
  return (
    <div class="w-max justify-center rounded-md bg-white py-2 px-6 shadow-lg">
      <form>
        <label for="newPost" class="form-label inline-block text-gray-700">
          New Post
        </label>
        <TextField
          className="w-full"
          required
          id="standard-required"
          label="Post Title"
          variant="standard"
          margin="normal"
          value={title}
          onChange={handleChangeTitle}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setPost(id, title, body).then((r) => {
                if (r) {
                  update();
                  setTitle("");
                  setBody("");
                }
              });
            }
          }}
          sx={{
            "& label.Mui-focused": {
              color: "#13BB7D",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#13BB7D",
            },
            "& .MuiInputLabel-root": {
              color: "#13BB7D",
              fontSize: "1.125rem",
              fontFamily: "Lato",
              lineHeight: "1.75rem",
            },
          }}
        />
        <textarea
          class="
        form-control
        m-0
        block
        w-full
        rounded-md
        border
        border-solid
        border-gray-300
        bg-white bg-clip-padding
        px-3 py-1.5 text-base
        font-normal
        text-gray-700
        transition
        ease-in-out
        focus:border-green-500 focus:bg-white focus:text-gray-700 focus:outline-none
      "
          value={body}
          onChange={handleChangeBody}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              setPost(id, title, body).then((r) => {
                if (r) {
                  update();
                  setTitle("");
                  setBody("");
                }
              });
            }
          }}
          id="newPost"
          rows="3"
          placeholder="Write your post here"
        ></textarea>

        <div className="mt-3 ml-auto w-max">
          <button
            onClick={() => {
              setPost(id, title, body).then((r) => {
                if (r) {
                  update();
                  setTitle("");
                  setBody("");
                }
              });
            }}
          >
            <div className="flex items-center w-10 h-10 p-2 mx-auto transition-all bg-green-500 rounded-full justify-right hover:bg-green-600">
              <i className="mx-auto text-lg text-white fas fa-paper-plane"></i>
            </div>
            <p className="text-xs text-center">Send</p>
          </button>
        </div>
      </form>
    </div>
  );
}
