import react, { useEffect } from "react";
import Navbar from "@components/Navbar";
import ProgressButton from "components/ProgressButton";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import { useLocation, useNavigate } from "react-router-dom";
import { requestOTP } from "scripts/requestOTP";
import Footer from "components/Footer";
import ErrorBoundary from "components/ErrorBoundary";
import { authentication } from "scripts/firebaseConfig";
import { RecaptchaVerifier } from "firebase/auth";
import LoadingSpinner from "components/LoadingSpinner";
import BackButton from "components/BackButton";

export default function Login() {
  const navigate = useNavigate();
  const [value, setValue] = react.useState("");
  const [loading, setLoading] = react.useState(false);
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          //ReCAPTCHA solved, allow signInWithPhoneNumber.
          setLoading(false);
          navigate(`code`, { state: { from: from } });
        },
      },
      authentication
    );
  });
  return (
    <div className="flex flex-col justify-between h-screen">
      {loading && <LoadingSpinner />}
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="col-span-3 mx-auto text-center text-green-500">
            What's Your Number?
          </h1>
        </div>
      </header>
      <main className="flex flex-col justify-between h-full mb-auto">
        <div className="mx-auto my-24 w-max">
          <ErrorBoundary>
            <PhoneInput
              defaultCountry="US"
              placeholder="Enter phone number"
              value={value}
              onChange={setValue}
            />
          </ErrorBoundary>
        </div>
        <div className="mx-auto mb-24 w-max">
          <ProgressButton
            onClick={() => {
              setLoading(true);
              requestOTP(value);
            }}
            enabled={isPossiblePhoneNumber(value + "")}
            to={``}
            text="Continue"
          />
        </div>
      </main>
      <div id="recaptcha-container"></div>
      <Footer />
    </div>
  );
}
