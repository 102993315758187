import "@fortawesome/fontawesome-free/css/all.css";
import { getDatabase, ref, set } from "firebase/database";
import selectDataUser from "./selectDataUser";


export default function deleteNotif(userID, notifEventID, notifTime) {
    return new Promise(function (resolve, reject) {
        console.log("d called");
        selectDataUser(userID).then(function (user) 
        {
            for(var i = 1; i <= user.notification.length - 1; i++)
            {
                if(user.notification[i].event === notifEventID && user.notification[i].time === notifTime )
                {
                    //console.log("FOUND: " + user.notification[i].time);
                    user.notification.splice(i, 1);
                    set(ref(getDatabase(), "Users/" + userID + "/notification"), user.notification);

                }
            }
        });
        try {
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
}