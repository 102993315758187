import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React from "react";
import AttendeeCard from "components/AttendeeCard";
import selectData from "scripts/selectData";
import getUsersForEventPage from "scripts/getUsersForEventPage";
import { useParams } from "react-router-dom";

export default function Attendees() {
  const [members, setMembers] = React.useState([
    {
      firstName: "Please Wait..",
      lastName: "...",
      role: 2,
      img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
      link: "temp",
    },
  ]);

  let { id } = useParams();
  React.useEffect(() => {
    selectData(id).then(function (tmp) {
      getUsersForEventPage(id).then(function (users) {
        setTimeout(function () {
          setMembers(users.users.sort((u1, u2) => u2.role - u1.role));
        }, 1500);
      });
    });
  }, [id]);

  return (
    <div className="flex h-screen flex-col justify-between">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="mx-auto mt-4 grid max-w-2xl grid-cols-5 items-center">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="col-span-3 mx-auto text-center text-green-500">
            Attendees
          </h1>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <div className="mx-auto my-8 flex h-96 w-max max-w-xl flex-col space-y-3 overflow-auto px-8 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
          {members.map((user) => (
            <AttendeeCard user={user} />
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
}
