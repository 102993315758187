import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React from "react";
import { useState } from "react";
import PollResultItem from "components/PollResultItem";
import selectData from "scripts/selectData";
import { useParams } from "react-router-dom";

export default function PollResults() {
  const [dates, setDates] = useState([]);
  const [dateAlreadyPicked, setDateAlreadyPicked] = useState(false);

  let { id } = useParams();

  React.useEffect(() => {
    selectData(id).then(function (event) {
      if (event.date !== "TBD") {
        setDateAlreadyPicked(true);
      }
      var masterList = [];
      event.potentialDates.forEach((date) => {
        masterList.push({ date: date, num: 0 });
      });
      event.members.forEach((member) => {
        for (var i = 0; i < masterList.length; i++) {
          if (member[2] !== undefined) {
            if (
              member[2][i][0] === masterList[i].date &&
              member[2][i][1] === true
            ) {
              masterList[i].num++;
            }
          }
        }
      });

      setDates(masterList.sort((a, b) => b.num - a.num));
    });
  }, [id]);
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="mx-auto text-center text-green-500 w-max">
            Select Event Date
          </h1>
          <div className="invisible"></div>
        </div>
      </header>
      {!dateAlreadyPicked && (
        <main className="px-4 mb-auto">
          <div className="w-3/4 mx-auto text-center">
            <span className="text-lg">
              Select the final date from the list of potential dates.
            </span>
          </div>
          <div className="w-full max-w-lg mx-auto my-6 space-y-2 overflow-auto h-96 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
            {dates.length > 0 &&
              dates.map((item, i) => <PollResultItem date={item} />)}
            {dates.length <= 0 && (
              <h2 className="mx-auto mt-10 text-green-500 w-max">
                No Poll Results Yet.
              </h2>
            )}
          </div>
        </main>
      )}
      {dateAlreadyPicked && (
        <main className="mb-auto">
          <h2 className="mx-auto mt-10 w-max">
            The date for this event has already been picked.
          </h2>
        </main>
      )}
      <Footer />
    </div>
  );
}
