import Navbar from "components/Navbar";
import Footer from "components/Footer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import ProgressButton from "components/ProgressButton";
import BackButton from "components/BackButton";
import setDate from "scripts/setDate";

export default function ResetDateDriverSingle() {
  const [value, setValue] = useState(new Date());
  let { id } = useParams();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex flex-col justify-between h-screen">
        <header>
          <Navbar
            variant={"green"}
            navItems={[
              { name: "Create Event", to: "/create" },
              { name: "My Events", to: "/my-events" },
            ]}
          />
          <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
            <div className="mx-auto w-max">
              <BackButton />
            </div>
            <div>
              <h1 className="text-center text-green-500">
                Select Event Date/Time
              </h1>
              <p className="mx-auto w-max">
                Select the date and time for your event.
              </p>
            </div>
            <div className="invisible"></div>
          </div>
        </header>
        <main className="mb-auto">
          <div className="mx-auto mt-10 w-max">
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"
              value={value}
              minDateTime={new Date()}
              onChange={(newValue) => {
                setValue(newValue);
              }}
            />
          </div>
          <div className="mx-auto mt-10 w-max">
            <ProgressButton
              onClick={() => {
                setDate(id, value);
              }}
              enabled={true}
              text="Continue"
              to={`/event/${id}`}
            />
          </div>
        </main>
        <Footer />
      </div>
    </LocalizationProvider>
  );
}
