import groupChatImage from "@img/home/group-chat.svg";
import scheduleImage from "@img/home/schedule.png";
import bullhorn from "@img/home/bullhorn.png";
import CTAButton from "@components/CTAButton";
import Navbar from "@components/Navbar";
import Footer from "components/Footer";

export default function Home() {
  return (
    <div className="flex h-screen flex-col justify-between">
      <header className="brand-gradient relative">
        <Navbar
          variant="white"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <h1 className="mx-auto mt-6 mb-4 w-3/4 text-center text-white sm:w-2/3 sm:text-5xl lg:mt-20 lg:w-1/2 lg:text-6xl">
          Take the stress out of planning.
        </h1>
        <p className="mx-auto w-3/4 text-center text-white sm:text-lg lg:text-2xl">
          Plan your next group event in minutes with Groupi.
        </p>
        <div className="mx-auto mt-6 w-max">
          <CTAButton text="Get Started" url="create" />
        </div>
        <svg
          className="crisp"
          viewBox="0 0 926 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 106C588.5 106 771.5 100.5 1026 0V106H0Z"
            fill="white"
          ></path>
        </svg>
      </header>
      <main className="mb-auto">
        <section className="py-10">
          <div className="px-10 py-12">
            <h1 className="mb-3 text-center text-green-500 lg:text-5xl">
              What is Groupi?
            </h1>
            <p className="mx-auto w-5/6 text-gray-500 sm:w-2/3 md:w-1/3 lg:text-lg">
              Groupi is a tool to let you plan events, invite friends, poll
              availability, and inform attendees of any changes to your event.
            </p>
          </div>
          <div className="flex flex-col justify-evenly bg-black bg-opacity-5 px-10 py-6 md:flex-row">
            <div className="flex items-center justify-center md:flex-col md:justify-between">
              <h3 className="w-1/2 sm:text-2xl md:hidden">
                Avoid cluttered group chats.
              </h3>
              <img
                className="w-36 sm:w-48"
                src={groupChatImage}
                alt="Group chat bubble"
              />
              <h3 className="hidden w-2/3 text-center text-2xl md:block">
                Avoid cluttered group chats.
              </h3>
            </div>
            <div className="flex items-center justify-center text-right md:flex-col md:justify-between">
              <img
                className="w-32 sm:w-36 md:mt-6"
                src={scheduleImage}
                alt="Calendar"
              />
              <h3 className="w-1/2 sm:text-2xl md:w-2/3 md:text-center">
                Keep track of availability.
              </h3>
            </div>
            <div className="flex items-center justify-center md:flex-col md:justify-between">
              <h3 className="w-1/2 sm:text-2xl md:hidden">
                Send announcements to attendees.
              </h3>
              <img className="w-36 sm:w-48" src={bullhorn} alt="Bullhorn" />
              <h3 className="hidden w-2/3 text-center text-2xl md:block">
                Send announcements to attendees.
              </h3>
            </div>
          </div>
          <div></div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
