import { Link } from "react-router-dom";

export default function CTAButton({ text, url, onClick }) {
  return (
    <div>
      <Link to={url} onClick={onClick}>
        <div className="flex items-center px-3 py-2 space-x-2 text-white transition-all border-2 border-white rounded-md group w-max hover:text-green-500 hover:bg-white">
          <span className="text-xl font-bold leading-none">{text}</span>
          <i className="text-lg transition-all duration-300 transform fas fa-arrow-right group-hover:translate-x-1"></i>
        </div>
      </Link>
    </div>
  );
}
