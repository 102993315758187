import "@fortawesome/fontawesome-free/css/all.css";
import { getDatabase, ref, set } from "firebase/database";
import selectDataUser from "./selectDataUser";


export default function addNotification(paramSenderUID, paramUserUID, paramType, paramEvent, paramLink) {
  class Notification {
    constructor(SentID, Type, Event, Link) {
      this.sentID = SentID; //uid of user who sent notification
      this.type = Type; //type, info, event
      this.event = Event;
      this.link = Link;   //link to where notification will take you
      this.status= false; //read or unread
      this.time = new Date().getTime();
    }
  }

  selectDataUser(paramUserUID).then(function (user) 
  {
    var obj = new Notification(paramSenderUID, paramType, paramEvent, paramLink);
    user.notification[user.notification.length] = obj;
    set(ref(getDatabase(), "Users/" + paramUserUID + "/notification"), user.notification);
  });
}

