import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
    apiKey: "AIzaSyD1kgH-0ECkNIVSGxUJ9VN5xwEhbE3guLo",
    authDomain: "groupi-916d6.firebaseapp.com",
    projectId: "groupi-916d6",
    storageBucket: "groupi-916d6.appspot.com",
    messagingSenderId: "373694064288",
    appId: "1:373694064288:web:7ceda5a754862952700bc5",
    measurementId: "G-MZVGKL35GT"
};

export const app = initializeApp(firebaseConfig)
export const authentication = getAuth(app)
export const db = getDatabase(app)

