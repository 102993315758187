import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React, { useState } from "react";
import PollDateItem from "@components/PollDateItem";
import ProgressButton from "components/ProgressButton";
import { db } from "scripts/firebaseConfig";
import selectData from "scripts/selectData";
import { set, ref } from "firebase/database";
import { useNavigate, useParams } from "react-router-dom";
import getUser from "scripts/getUser";

export default function Poll() {
  //THESE 2 ARRAYS NEED TO ALWAYS BE THE SAME LENGTH
  const [dates, setDates] = React.useState([
    new Date(),
    new Date(),
    new Date(),
  ]);
  const [selected, setSelected] = React.useState(
    new Array(dates.length).fill(false)
  );
  const [dateAlreadyPicked, setDateAlreadyPicked] = useState(false);

  let { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    selectData(id).then(function (event) {
      if (event.date !== "TBD") {
        setDateAlreadyPicked(true);
      }
      var tmpArr = [];
      event.potentialDates.forEach((element) => {
        tmpArr.push(new Date(element));
      });
      getUser().then(function (userAuth) {
        var tempSelected = new Array(tmpArr.length).fill(false);
        event.members.forEach((element) => {
          if (element[0] === userAuth.uid && element[2] !== null) {
            for (var i = 0; i < element[2].length; i++) {
              if (element[2][i][1] === true) {
                tempSelected[i] = true;
              }
            }
            setSelected(tempSelected);
          } else {
            setSelected(new Array(tmpArr.length).fill(false));
          }
        });
      });
      setDates(tmpArr);
    });
  }, [id]);

  const selectItem = (index) => {
    let temp = selected.slice();
    temp[index] = !temp[index];
    setSelected(temp);
  };
  let selectedDates = [];
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="mx-auto text-green-500 w-max">Availibilty</h1>
          <div className="invisible"></div>
        </div>
      </header>
      {!dateAlreadyPicked && (
        <main className="mb-auto">
          <div className="mx-auto w-max">
            <span className="text-lg">Select dates when you are available</span>
            <div className="w-full max-w-md mx-auto my-6 space-y-2 overflow-auto h-96 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
              {dates.map((item, i) => (
                <PollDateItem
                  date={item}
                  index={i}
                  onSelect={selectItem}
                  state={selected[i]}
                />
              ))}
            </div>
            <div className="mx-auto mb-24 w-max">
              <ProgressButton
                onClick={() => {
                  for (let i = 0; i < selected.length; ++i) {
                    if (selected[i]) selectedDates.push(dates[i]);
                  }
                  getUser().then(function (userAuth) {
                    selectData(id).then(function (event) {
                      event.members.forEach((element) => {
                        if (element[0] === userAuth.uid.toString()) {
                          element[2] = [];
                          for (var i = 0; i < dates.length; i++) {
                            element[2].push([dates[i].getTime(), selected[i]]);
                          }
                          set(
                            ref(db, "Events/" + event.id.toString()),
                            event
                          ).then(() => {
                            navigate(`/event/${id}`);
                          });
                        }
                      });
                    });
                  });
                }}
                enabled="true"
                to={``}
                text="Done"
              />
            </div>
          </div>
        </main>
      )}
      {dateAlreadyPicked && (
        <main className="mb-auto">
          <h2 className="mx-auto mt-10 w-max">
            The date for this event has already been picked.
          </h2>
        </main>
      )}
      <Footer />
    </div>
  );
}
