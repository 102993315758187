import Navbar from "@components/Navbar";
import ErrorBoundary from "components/ErrorBoundary";
import PostCard from "components/PostCard";
import UserIcon from "components/UserIcon";
import { Link, useParams, useNavigate } from "react-router-dom";
import logoWhite from "@img/brand/logo-white.svg";
import Footer from "components/Footer";
import React from "react";
import selectData from "scripts/selectData";
import getUsersForEventPage from "scripts/getUsersForEventPage";
import { Menu, Popover, Transition } from "@headlessui/react";
import CreatePost from "@components/CreatePost";
import getDate from "scripts/getDate";
import getTime from "scripts/getTime";
import getUser from "scripts/getUser";
import ReactTooltip from "react-tooltip";
import deleteEvent from "scripts/deleteEvent";
import Modal from "components/Modal";
import selectDataUser from "scripts/selectDataUser";
import leaveEvent from "scripts/leaveEvent";

export default function Event() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = React.useState(0);
  const [userID, setUserID] = React.useState(null);
  const [title, setTitle] = React.useState("title");
  const [description, setDescription] = React.useState("description");
  const [location, setLocation] = React.useState("location");
  const [date, setDate] = React.useState("date");
  const [time, setTime] = React.useState("time");
  const [showModal, setShowModal] = React.useState(false);
  const [members, setMembers] = React.useState([
    {
      firstName: "Please Wait..",
      lastName: "...",
      role: 2,
      img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
      link: "temp",
    },
  ]);
  const [posts, setPosts] = React.useState([
    {
      text: "temp",
      time: "2h ago",
      link: "temp",
      user: {
        firstName: "temp",
        lastName: "temp",
        role: 2,
        link: "temp",
        img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
      },
    },
  ]);
  let { id } = useParams();
  React.useEffect(() => {
    selectData(id).then(function (tmp) {
      setTitle(tmp.title);
      setDescription(tmp.description);
      setLocation(tmp.location);
      getUser().then(function (userAuth) {
        setUserID(userAuth.uid);
        tmp.members.forEach((element) => {
          if (element[0] === userAuth.uid) {
            setUserRole(element[1]);
            if (element[2] === undefined && tmp.date === "TBD") {
              navigate("poll", { replace: true });
            }
          }
        });
      });
      //set Date
      if (tmp.date !== "TBD") {
        setDate(getDate(tmp.date));
      } else {
        setDate(tmp.date);
      }
      //set Time
      if (tmp.date !== "TBD") {
        setTime(getTime(tmp.date));
      } else {
        setTime(tmp.date);
      }
      //setMembers(tmp.members)
      getUsersForEventPage(id).then(function (users) {
        setTimeout(function () {
          setMembers(users.users.sort((u1, u2) => u2.role - u1.role));
        }, 500);
      });
      tmp.posts.slice(2).forEach((element) => {
        selectDataUser(element.user.id).then(function (user) {
          element.user.firstName = user.firstName;
          element.user.lastName = user.lastName;
          element.user.img = user.img;
        });
      });
      setPosts(tmp.posts);
    });
  }, [id, navigate]);

  const updatePosts = () => {
    selectData(id).then(function (tmp) {
      tmp.posts.slice(2).forEach((element) => {
        selectDataUser(element.user.id).then(function (user) {
          element.user.firstName = user.firstName;
          element.user.lastName = user.lastName;
          element.user.img = user.img;
        });
      });
      setTimeout(function () {
        setPosts(tmp.posts);
      }, 500);
    });
  };

  const updateUsers = () => {
    getUsersForEventPage(id).then(function (users) {
      if (users !== null) {
        setTimeout(function () {
          setMembers(users.users.sort((u1, u2) => u2.role - u1.role));
        }, 500);
      }
    });
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      {showModal && (
        <Modal
          title="Delete Event"
          description={
            <span>
              Are you sure you want to delete your event? <br /> (This action
              cannot be undone.)
            </span>
          }
          button2Text="Delete"
          button1Text="Cancel"
          button2Color={"red"}
          onClick2={() => {
            getUser().then(function (userAuth) {
              deleteEvent(id, userAuth.uid);
              navigate(`/`);
            });
          }}
          onClick1={() => {
            setShowModal(false);
          }}
          open={showModal}
          setOpen={setShowModal}
        />
      )}
      <header>
        <Navbar
          variant={"green"}
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="relative grid max-w-xl grid-cols-5 mx-auto">
          <h1 className="col-span-3 col-start-2 leading-none text-center text-green-500">
            {title}
          </h1>

          <div className="relative">
            <Menu>
              <Menu.Button className="w-6 h-6 p-2">
                <i className="text-2xl text-gray-700 transition-all duration-300 transform fas fa-gear hover:rotate-180 hover:text-green-500"></i>
              </Menu.Button>
              <Transition
                enter="transition ease-out duration-200 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Menu.Items
                  className="absolute right-0 z-50 px-2 py-3 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  {userRole === 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-green-500 hover:text-white"
                              : "text-gray-700"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              navigate(`resetdatemulti`);
                            }}
                          >
                            Change Possible Dates/Times
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                  {userRole === 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-green-500 hover:text-white"
                              : "text-gray-700"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              navigate(`resetdatesingle`);
                            }}
                          >
                            Manually Set Final Date
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                  {userRole === 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-green-500 hover:text-white"
                              : "text-gray-700"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              navigate(`editEvent`);
                            }}
                          >
                            Edit Event Info
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                  {userRole === 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-red-500 hover:text-white"
                              : "text-red-500"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            Delete Event
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                  {userRole !== 2 && (
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-red-500 hover:text-white"
                              : "text-red-500"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              getUser().then(function (userAuth) {
                                leaveEvent(id, userAuth.uid);
                              });
                              navigate(`/`);
                            }}
                          >
                            Leave Event
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="mx-auto text-sm text-gray-700 w-max md:mt-2 md:flex md:space-x-6">
          <div className="flex items-center">
            <i className="mr-1 fas fa-clock"></i>
            {date !== "TBD" && (
              <span>
                {date} - {time}
              </span>
            )}
            {date === "TBD" && userRole !== 2 && (
              <div className="ml-1 transform w-max">
                <Link to="poll">
                  <div className="mr-1 text-sm text-right text-black transition-all duration-300 group w-max hover:text-green-500">
                    Set Availability
                    <i className="ml-1 transition duration-300 fas fa-arrow-right group-hover:translate-x-1" />
                  </div>
                </Link>
              </div>
            )}
            {date === "TBD" && userRole === 2 && (
              <div className="ml-1 transform w-max">
                <Link to="results">
                  <div className="mr-1 text-sm text-right text-black transition-all duration-300 group w-max hover:text-green-500">
                    Choose Final Date
                    <i className="ml-1 transition duration-300 fas fa-arrow-right group-hover:translate-x-1" />
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div>
            <i className="mr-1 fas fa-map-marker-alt"></i>
            {location}
          </div>
        </div>
        <p className="w-3/4 mx-auto my-2 text-xs text-center text-gray-700">
          {description}
        </p>
      </header>
      <main className="relative mb-auto">
        <div className="mx-auto md:flex md:w-3/4 md:justify-center">
          <div className="w-2/3 mx-auto text-green-500 md:w-max">
            <div className="flex items-center space-x-2">
              <h3>Attendees</h3>
              <div className="flex items-center w-6 h-6 text-center text-white bg-green-500 rounded-full">
                <span className="w-full text-center">{members.length}</span>
              </div>
            </div>

            <div className="flex flex-wrap items-center space-x-3 md:flex-col md:space-x-0 md:space-y-2">
              <ErrorBoundary>
                {members
                  .filter((item, index) => index < 5)
                  .map((attendee) => (
                    <div>
                      <div className="mx-auto w-max">
                        <UserIcon
                          user={attendee}
                          currentUid={userID}
                          currentRole={userRole}
                          eventID={id}
                          update={updateUsers}
                        />
                      </div>
                      <p className="text-xs text-center">
                        {attendee.firstName} {attendee.lastName.charAt(0)}
                      </p>
                    </div>
                  ))}
                <div className="relative w-max">
                  <Link to={`/invite/${id}`}>
                    <div>
                      <div className="flex items-center w-10 h-10 p-2 mx-auto transition-all bg-green-500 rounded-full hover:bg-green-600">
                        <img className="w-5 h-5" src={logoWhite} alt="Invite" />
                        <i className="text-xs text-white fas fa-plus"></i>
                      </div>
                      <p className="text-xs text-center">Invite</p>
                    </div>
                  </Link>
                </div>
              </ErrorBoundary>
            </div>
            <div className="ml-auto transform w-max">
              <Link to="attendees">
                <div className="mt-1 mr-1 text-sm text-right text-black transition-all duration-300 group w-max hover:text-green-500">
                  View All
                  <i className="ml-1 transition duration-300 fas fa-arrow-right group-hover:translate-x-1" />
                </div>
              </Link>
            </div>
          </div>
          <div className="w-full">
            <h3 className="w-3/4 max-w-sm mx-auto text-green-500 md:w-full md:max-w-md lg:max-w-xl">
              Posts
            </h3>
            <ErrorBoundary>
              {posts.length > 1 &&
                posts
                  .slice(1)
                  .reverse()
                  .map((post) => (
                    <PostCard
                      post={post}
                      eventID={id}
                      update={updatePosts}
                      userRole={userRole}
                      userID={userID}
                    />
                  ))}
              {posts.length <= 1 && (
                <h2 className="mx-auto mt-16 text-green-500 w-max">
                  No posts here!
                </h2>
              )}
            </ErrorBoundary>
          </div>
        </div>
      </main>
      <div className="sticky bottom-5">
        <Popover>
          <div>
            <div className="3xl:mr-[30rem] my-6 ml-auto mr-6 w-max lg:mr-20 xl:mr-[10rem] 2xl:mr-[20rem]">
              <Popover.Button>
                <div
                  className="flex items-center p-2 mx-auto bg-green-500 rounded-full h-14 w-14"
                  data-tip
                  data-for={"newpost"}
                >
                  <i className="mx-auto text-2xl text-white fas fa-pen-to-square"></i>
                  <ReactTooltip id={"newpost"} type="dark" effect="solid">
                    <span>New Post</span>
                  </ReactTooltip>
                </div>
              </Popover.Button>
            </div>
            <Transition
              enter="transition ease-out duration-200 transform"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-100 transform"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel className="absolute origin-bottom-right right-10 -top-64 md:right-20">
                <CreatePost id={id} update={updatePosts} />
              </Popover.Panel>
            </Transition>
          </div>
        </Popover>
      </div>

      <Footer />
    </div>
  );
}
