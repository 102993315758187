export default function NotificationBadge({ num }) {
  let val = num < 100 ? num.toString() : "99+";
  let len = val.length;
  let classes = "";
  if (len === 1) {
    classes += " w-5 h-5";
  }
  if (len < 3) {
    classes += " right-0";
  }
  return (
    <span
      className={`absolute p-1 text-xs leading-none text-white bg-red-500 rounded-full -top-2 right-${classes}`}
    >
      {num < 100 ? num : "99+"}
    </span>
  );
}
