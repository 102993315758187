import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React from "react";
import selectData from "scripts/selectData";
import { useParams, useNavigate } from "react-router-dom";
import ProgressButton from "components/ProgressButton";
import { db } from "scripts/firebaseConfig";
import { ref, set } from "firebase/database";

export default function PostEditDriver() {
  const navigate = useNavigate();
  const [newTitle, setNewTitle] = React.useState();
  const [newText, setNewText] = React.useState();

  let { id, PostID } = useParams();

  React.useEffect(() => {
    selectData(id).then(function (event) {
      event.posts.forEach((element) => {
        if (element.id === Number.parseInt(PostID, 10)) {
          setNewText(element.text);
          setNewTitle(element.title);
        }
      });
    });
  }, [id, PostID]);
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="flex items-center w-3/5 mx-auto mt-4 justify-evenly">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <input
          value={newTitle}
          onChange={(e) => {
            setNewTitle(e.target.value);
          }}
        ></input>
        <input
          value={newText}
          onChange={(e) => {
            setNewText(e.target.value);
          }}
        ></input>
        <ProgressButton
          onClick={() => {
            selectData(id).then(function (event) {
              event.posts.forEach((element) => {
                if (Number.parseInt(PostID, 10) === element.id) {
                  element.title = { newTitle }.newTitle;
                  element.text = { newText }.newText;
                  set(ref(db, "Events/" + event.id.toString()), event).then(
                    () => {
                      navigate(`/event/${id}`);
                    }
                  );
                }
              });
            });
          }}
          enabled={(newText && newTitle) !== (null || "")}
          to={""}
          text="Edit Post"
        />
      </main>
      <Footer />
    </div>
  );
}
