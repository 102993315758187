import {useEffect} from "react";
//import {useParams, useNavigate} from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import getUser from "scripts/getUser";
import ProgressButton from "components/ProgressButton";
//import addNotification from "scripts/addNotification";
import changeEventLocation from "scripts/changeEventLocation";
export default function InviteDriver() {
    const navigate = useNavigate();
    useEffect(()=>
    {
        getUser().then(function (user) {
            if(user === null)
            {
                navigate("/login");
            }
        });
        
    });
    return (
        <div>   
    <p>Click button to append notification to user</p>
    <ProgressButton
            onClick={() => {
                /*
                getUser().then(function (user) {
                    var sender = "75ogCuIYveYT5y98rhPGdOznajq2";
                    var userID = user.uid;
                    var type = "event_join";
                    var event = "1000780617950000";
                    var link = "google.com";
                    addNotification(sender, userID, type, event, link);
                });    
                */
               changeEventLocation("5191994026290000", "My house >:)");
            }}
            enabled={true}     
            text="Join"
            to={''}
            />
    </div>
    );
    
}

