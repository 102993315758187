import CTAButton from "@components/CTAButton";
import Navbar from "@components/Navbar";
import Footer from "components/Footer";
import logo from "@img/brand/logo-white.svg";
import { useParams } from "react-router-dom";
import getUser from "scripts/getUser";
import inviteUser from "scripts/inviteUser";
import { useNavigate } from "react-router-dom";
import react, { useEffect } from "react";
import selectData from "scripts/selectData";
import addNotification from "scripts/addNotification";
export default function Invitation() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [eventName, setEventName] = react.useState("");
  useEffect(() => {
    getUser().then(function (user) {
      selectData(id).then(function (event) {
        setEventName(event.title);
        event.members.forEach((element) => {
          if (element[0] === user.uid) {
            navigate(`/event/${id}`);
          }
        });
      });
    });
  });
  return (
    <div className="flex h-screen flex-col justify-between bg-white">
      <header className="brand-gradient relative">
        <Navbar
          variant="white"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
      </header>
      <main className="mb-auto">
        <section className="py-10">
          <div className="mx-auto flex w-full max-w-sm flex-row justify-evenly rounded-lg bg-green-500 px-10 py-6 sm:max-w-md md:max-w-lg">
            <div className="flex items-center justify-center md:flex-col md:justify-between">
              <img className="w-24 sm:w-36" src={logo} alt="Groupi logo" />
            </div>

            <div className="mx-auto mt-6 w-max text-center text-white">
              <h2> You have been invited to join</h2>
              <div className="mx-auto mt-6 w-max text-center text-white">
                <h1> {eventName} </h1>
              </div>
              <div className="mx-auto mt-6 w-max justify-items-center">
                <CTAButton
                  text="Accept invite"
                  onClick={() => {
                    getUser().then(function (user) {
                      selectData(id).then(function (event) {
                        event.members.forEach((member) => {
                          //console.log("ADDING NOTIFICATION FROM " + userAuth.uid + " TO " + member[0] + "FROM EVENT: " + id);
                          if(member[0] !== user.uid )
                          {
                            addNotification(user.uid, member[0], "event_join", id, "/event/" + id);
                          }
                        });
                      });
                      //check if our user is already within the event
                      selectData(id).then(function (event){
                        if(event.members.length < 50){
                          inviteUser(id, user.uid).then(function (done) {
                            if (done !== null) {
                              navigate(`/event/${id}`);
                            }
                          });
                        }
                        else{
                          navigate(`/eventFull`);
                        }
                      });
                    });
                  }}
                  url=""
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
