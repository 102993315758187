import UserIcon from "components/UserIcon";
import { Link } from "react-router-dom";

export default function AttendeeCard({ user }) {
  let role = !user.role ? "" : user.role === 1 ? "- Moderator" : "- Organizer";
  return (
    <Link to={user.link}>
      <div className="flex w-full max-w-xs flex-row items-center space-x-3 rounded-md bg-gray-50 bg-opacity-5 p-2 shadow-md transition-all hover:bg-black hover:bg-opacity-5 md:w-full md:max-w-md lg:max-w-xl">
        <div className="mx-1 flex w-max items-center space-x-3">
          <div className="mx-auto w-max">
            <UserIcon user={user} />
          </div>
          <span className="mx-auto w-max text-lg text-green-500">
            {user.firstName} {user.lastName}
          </span>
          <span className="text-sm text-gray-200">{role}</span>
        </div>
      </div>
    </Link>
  );
}
