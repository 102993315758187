import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import LoadingSpinnerFull from "./LoadingSpinnerFull";

export default function RequireAuth() {
  const [isLoggedIn, setIsLoggedin] = React.useState(null);
  const location = useLocation();
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null) {
        setIsLoggedin(true);
      } else {
        setIsLoggedin(false);
      }
    });
  });
  return isLoggedIn !== null ? (
    isLoggedIn ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  ) : (
    <LoadingSpinnerFull />
  );
}
