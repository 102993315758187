import "@fortawesome/fontawesome-free/css/all.css";
import { db } from "./firebaseConfig";
import selectData from "./selectData";
import { set, ref } from "firebase/database";
import getUser from "./getUser";
import addNotification from "./addNotification";
export default function setDate(eventID, Date) {
  selectData(eventID).then(function (event) {
    getUser().then(function (user) {
      event.members.forEach((member) => {
        //console.log("ADDING NOTIFICATION FROM " + userAuth.uid + " TO " + member[0] + "FROM EVENT: " + id);
        if (member[0] !== user.uid) {
          addNotification(
            user.uid,
            member[0],
            "date_change",
            eventID,
            "/event/" + eventID
          );
        }
      });
    });
    event.date = Date.getTime();
    set(ref(db, "Events/" + event.id.toString()), event);
  });
}
