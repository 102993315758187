//import "./index.css";
import "@fortawesome/fontawesome-free/css/all.css";
// Import the functions you need from the SDKs you need
import { db } from "scripts/firebaseConfig";
//import {getDatabase, ref, set} from 'https://www.gstatic.com/firebasejs/9.6.8/firebase-database.js';
import { ref, onValue } from "firebase/database";

//Opens firebase entry for event at ID
export default function selectData(ID) {
  //console.log(db);
  const dbref = ref(db, "Events/" + ID);
  return new Promise(function (resolve, reject) {
    try {
      onValue(dbref, (snapshot) => {
        const data = snapshot.val();
        resolve(data);
      });
    } catch (e) {
      reject(e);
    }
  });
}
