export default function Logo() {
  return (
    <div id="logo" className="flex items-center space-x-2">
      <h2>Groupi</h2>
      <svg
        id="logo-svg"
        width="36"
        height="36"
        viewBox="0 0 225 225"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Head */}
        <path
          id="logo-head"
          d="M62.4375 111.375C93.1929 111.375 118.125 86.4429 118.125 55.6875C118.125 24.9321 93.1929 0 62.4375 0C31.6821 0 6.75 24.9321 6.75 55.6875C6.75 86.4429 31.6821 111.375 62.4375 111.375Z"
        />
        {/* Body */}
        <path
          id="logo-body"
          d="M0 162.562C0 128.079 27.9542 100.125 62.4375 100.125C96.9208 100.125 124.875 128.079 124.875 162.562V225H0V162.562Z"
        />
        {/* Arm */}
        <path
          id="logo-arm"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M183.022 42.0739C172.613 34.3539 157.916 36.5342 150.196 46.9438L107.263 104.833C121.956 114.28 131.906 129.83 134.24 147.243L187.892 74.9004C195.612 64.4908 193.432 49.7939 183.022 42.0739Z"
        />
      </svg>
    </div>
  );
}
