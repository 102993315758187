import "@fortawesome/fontawesome-free/css/all.css";
import getUser from "./getUser";

// Import the functions you need from the SDKs you need

import { getDatabase, ref, set } from "firebase/database";
import selectDataUser from "./selectDataUser";

//Creates a new user with phone number set to paramPhoneNumber
export default function addNewUser(paramFirstName, paramLastName, paramImg) {
  return new Promise(function (resolve, reject) {
    class User {
      constructor(
        ID,
        PhoneNumber,
        firstName,
        lastName,
        Events,
        Notification,
        Friends,
        Img,
        NewUser
      ) {
        this.id = ID;
        this.phoneNumber = PhoneNumber;
        this.firstName = firstName;
        this.lastName = lastName;
        this.events = Events;
        this.notification = Notification;
        this.friends = Friends;
        this.img = Img;
        this.newUser = NewUser;
      }
    }

    // 'file' comes from the Blob or File API

    //hard coded events and notification to be changed later
    //console.log("in addNewUser")
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (usr) {
        var obj;
        if (usr === null) {
          obj = new User(
            user.uid,
            user.phoneNumber,
            paramFirstName,
            paramLastName,
            [""],
            [""],
            [""],
            paramImg,
            true
          );
          set(ref(getDatabase(), "Users/" + obj.id.toString()), obj);
        } else {
          obj = new User(
            user.uid,
            user.phoneNumber,
            paramFirstName,
            paramLastName,
            usr.events,
            usr.notification,
            usr.friends,
            paramImg,
            false
          );
          set(ref(getDatabase(), "Users/" + obj.id.toString()), obj);
        }
      });
    });
    try {
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
