import { Navigate, Outlet, useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import LoadingSpinnerFull from "./LoadingSpinnerFull";
import selectData from "scripts/selectData";

export default function RequireInvite() {
  const [isInEvent, setisInEvent] = React.useState(null);
  const { id } = useParams();
  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user !== null) {
        selectData(id).then(function (event) {
          if (event !== null) {
            let temp = false;
            for (const item of event.members) {
              if (item.includes(user.uid)) {
                temp = true;
              }
            }
            if (temp) {
              setisInEvent(true);
            } else {
              setisInEvent(false);
            }
          } else {
            setisInEvent(false);
          }
        });
      } else {
        setisInEvent(false);
      }
    });
  });
  return isInEvent !== null ? (
    isInEvent ? (
      <Outlet />
    ) : (
      <Navigate to="/eventNotFound" replace />
    )
  ) : (
    <LoadingSpinnerFull />
  );
}
