export default function getTime(unix) {
    var nDate = new Date(unix);
    var tempTime;
    if(nDate.getHours() > 12 && nDate.getMinutes().toString().length === 1){
      tempTime = (nDate.getHours().toString() - 12) + ':0' + nDate.getMinutes().toString() + 'pm';
    }
    else if (nDate.getHours() > 12){
      tempTime = (nDate.getHours().toString() - 12) + ':' + nDate.getMinutes().toString() + 'pm';
    }
    else if (nDate.getMinutes().toString().length === 1){
      tempTime = nDate.getHours().toString() + ':0' + nDate.getMinutes().toString() + 'am';
    }
    else{
      tempTime = (nDate.getHours()).toString() + ':' + nDate.getMinutes().toString() + 'am';
    }

    return (tempTime);
}