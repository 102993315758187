import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BackButton from "@components/BackButton";
import React from "react";
import selectDataUser from "scripts/selectDataUser";
import getUser from "scripts/getUser";
import addNewUser from "scripts/addUser";
import { useParams } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

function isFileImage(file) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];

  return file && acceptedImageTypes.includes(file["type"]);
}

function uploadBlob(blob) {
  return new Promise(function (resolve, reject) {
    if (isFileImage(blob)) {
      //console.log(blob);
      const storage = getStorage();
      getUser().then(function (user) {
        const storageRef = ref(storage, "images/" + user.uid);
        uploadBytes(storageRef, blob).then((snapshot) => {
          getDownloadURL(ref(storage, "images/" + user.uid)).then((url) => {
            resolve(url);
          });
        });
      });
    } else {
      resolve(
        "https://firebasestorage.googleapis.com/v0/b/groupi-916d6.appspot.com/o/images%2FdefaultPfp.png?alt=media&token=87da0092-0f0d-4953-9c5f-992f08f6000f"
      );
    }
  });
}

export default function MyProfile() {
  const [user, setUser] = React.useState({
    firstName: "Please Wait..",
    lastName: "...",
    img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
    phoneNumber: "loading",
  });
  const [editModeName, setEditModeName] = React.useState(false);
  const [editModeImg, setEditModeImg] = React.useState(false);
  const [newFirstName, setNewFirstName] = React.useState(null);
  const [newLastName, setNewLastName] = React.useState(null);
  const [newImg, setNewImg] = React.useState(null);
  const [currentUsr, SetCurrentUsr] = React.useState(null);

  const handleChange2 = (event) => {
    setNewLastName(event.target.value);
  };
  const handleChange1 = (event) => {
    setNewFirstName(event.target.value);
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setNewImg(img);
    }
  };

  let { id } = useParams();

  React.useEffect(() => {
    getUser().then(function (user) {
      SetCurrentUsr(user.uid);
    });
    selectDataUser(id).then(function (x) {
      if (x !== null) {
        setUser(x);
        setNewFirstName(x.firstName);
        setNewLastName(x.lastName);
      }
    });
  }, [id]);

  const updateImg = () => {
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (x) {
        if (x !== null) {
          setUser(x);
          setNewImg(null);
        }
      });
    });
  };

  const updateName = () => {
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (x) {
        if (x !== null) {
          setUser(x);
          setNewFirstName(x.firstName);
          setNewLastName(x.lastName);
        }
      });
    });
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="col-span-3 mx-auto text-center text-green-500">
            {{ newFirstName }.newFirstName +
              " " +
              { newLastName }.newLastName +
              "'s Profile"}
          </h1>
        </div>
      </header>

      <main className="mb-auto">
        <section className="py-10">
          <div className="flex flex-col items-center w-full max-w-sm px-10 py-6 mx-auto rounded-lg justify-evenly sm:max-w-md md:max-w-lg">
            <img
              className="object-cover rounded-full h-36 w-36"
              src={
                editModeImg
                  ? newImg !== null
                    ? URL.createObjectURL(newImg)
                    : user.img
                  : user.img
              }
              alt="user icon"
            />
            {currentUsr === id && (
              <div>
                <div className="space-x-2">
                  {editModeImg && (
                    <button
                      onClick={() => {
                        setNewImg(null);
                        setEditModeImg(!editModeImg);
                      }}
                    >
                      <span className="font-bold text-green-500 hover:text-green-600 hover:underline">
                        Cancel
                      </span>
                    </button>
                  )}
                  {editModeImg && <span>-</span>}
                  <button
                    onClick={() => {
                      if (editModeImg && newImg !== null) {
                        uploadBlob({ newImg }.newImg).then(function (img) {
                          getUser().then(function (x) {
                            addNewUser(user.firstName, user.lastName, img).then(
                              (r) => {
                                if (r) {
                                  updateImg();
                                }
                              }
                            );
                          });
                        });
                      }
                      setEditModeImg(!editModeImg);
                    }}
                  >
                    <span className="font-bold text-green-500 hover:text-green-600 hover:underline">
                      {editModeImg ? "Save" : "Edit"}
                    </span>
                  </button>
                </div>

                {editModeImg && (
                  <div className="flex flex-col items-center w-full mx-auto justify-evenly">
                    <input
                      className="form-control m-0 w-[186px] bg-white px-1 py-1 text-sm font-normal text-gray-700 transition ease-in-out"
                      type="file"
                      name="myImage"
                      onChange={onImageChange}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="mx-auto mt-6 w-max">
              <h2>
                {user.firstName} {user.lastName}
              </h2>
              {currentUsr === id && (
                <div>
                  <div className="space-x-2">
                    {editModeName && (
                      <button
                        onClick={() => {
                          setNewFirstName(user.firstName);
                          setNewLastName(user.lastName);
                          setEditModeName(!editModeName);
                        }}
                      >
                        <span className="font-bold text-green-500 hover:text-green-600 hover:underline">
                          Cancel
                        </span>
                      </button>
                    )}
                    {editModeName && <span>-</span>}
                    <button
                      onClick={() => {
                        if (editModeName) {
                          addNewUser(newFirstName, newLastName, user.img).then(
                            (r) => {
                              if (r) {
                                updateName();
                              }
                            }
                          );
                        }
                        setEditModeName(!editModeName);
                      }}
                    >
                      <span className="font-bold text-green-500 hover:text-green-600 hover:underline">
                        {" "}
                        {editModeName ? "Save" : "Edit"}{" "}
                      </span>
                    </button>
                  </div>
                  {editModeName && (
                    <div className="mx-auto w-max">
                      <Box
                        component="form"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          "& .MuiTextField-root": { width: "25ch" },
                        }}
                      >
                        <TextField
                          required
                          id="standard-required"
                          label="First Name"
                          variant="standard"
                          margin="normal"
                          value={newFirstName}
                          onChange={handleChange1}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#13BB7D",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#13BB7D",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#13BB7D",
                              fontSize: "1.125rem",
                              fontFamily: "Lato",
                              lineHeight: "1.75rem",
                            },
                          }}
                        />
                        <TextField
                          required
                          id="standard"
                          label="Last Name"
                          variant="standard"
                          margin="normal"
                          value={newLastName}
                          onChange={handleChange2}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#13BB7D",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#13BB7D",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#13BB7D",
                              fontSize: "1.125rem",
                              fontFamily: "Lato",
                              lineHeight: "1.75rem",
                            },
                          }}
                        />
                      </Box>
                    </div>
                  )}
                  <div className="text-gray-300">
                    <h3 className="font-thin">{user.phoneNumber}</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
