import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import EventCard from "components/EventCard";
import React from "react";
import selectDataUser from "scripts/selectDataUser";
import getUser from "scripts/getUser";

export default function MyEvents() {
  const [events, setEvents] = React.useState([]);
  React.useEffect(() => {
    getUser().then(function (user) {
      selectDataUser(user.uid).then(function (x) {
        if (x !== null) {
          setEvents(x.events.slice(1));
        }
      });
    });
  }, []);
  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-2xl grid-cols-5 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="col-span-3 mx-auto text-center text-green-500">
            My Events
          </h1>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <div className="mx-auto">
          <div className="flex flex-col w-full max-w-xl px-8 mx-auto my-8 space-y-3 overflow-auto h-96 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
            {events.map((event) => (
              <EventCard id={event} />
            ))}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
