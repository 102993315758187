import React from "react";
import { Link } from "react-router-dom";
import logoAlt from "@img/brand/logo.svg";
import { Transition, Menu, RadioGroup } from "@headlessui/react";
import NotificationBadge from "./NotificationBadge";
import { getAuth, signOut } from "firebase/auth";
import selectDataUser from "scripts/selectDataUser";
import getUser from "scripts/getUser";
import Logo from "./Logo";
import NotificationFactory from "./NotificationFactory";


export default function Navbar({ variant, navItems }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [notifType, setNotifType] = React.useState("all");
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [uid, setUid] = React.useState(null);
  const [pfp, setPfp] = React.useState(logoAlt);
  const [name, setName] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [notifArr, setNotifArr] = React.useState(null);
  const [numNotif, setNumNotif] = React.useState();

  getAuth().onAuthStateChanged((user) => {
    if (user != null) {
      setIsLoggedIn(true);
      setUid(user.uid);
      selectDataUser(user.uid).then(function (path) {
        if (path !== null) {
          setPfp(path.img);
          setName(`${path.firstName} ${path.lastName}`);
          setPhoneNum(path.phoneNumber);
        }
      });
    } else {
      setIsLoggedIn(false);
    }
  });

  React.useEffect(() => {
    getUser().then(function (user) {
      if (user === null) {
      } else {
        //setUserID(user.uid);

        selectDataUser(user.uid).then(function (x) {
          setNotifArr(x.notification);
          setNumNotif(x.notification.length - 1);
        });
      }
    });
  }, []);

  

  let navStyles =
    variant === "white"
      ? "text-white hover:bg-green-600"
      : "text-green-500 hover:bg-black hover:bg-opacity-5";
  let menuBgStyles =
    variant === "white"
      ? "hover:bg-green-600"
      : "hover:bg-black hover:bg-opacity-5";
  let menuTextStyles = variant === "white" ? "text-white " : "text-green-500";
  let logoStyle = variant === "white" ? "fill-white" : "fill-green-500";
  let logoTextStyle = variant === "white" ? "text-white" : "text-green-500";
  return (
    <div className="mx-auto max-w-7xl">
      <nav
        className={
          navbarOpen
            ? " bg-white shadow-md lg:bg-transparent lg:shadow-none"
            : "bg-transparent "
        }
      >
        <div className="px-4 py-1 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-grow h-16">
            <div className="">
              <Link to="/">
                <div
                  className={`${
                    navbarOpen
                      ? `fill-green-500 text-green-500 lg:${logoStyle} lg:${logoTextStyle}`
                      : `${logoStyle} ${logoTextStyle}`
                  }`}
                >
                  <Logo />
                </div>
              </Link>
            </div>
            <div className="flex items-center">
              <div className="hidden lg:block">
                <div className="flex items-baseline space-x-4">
                  {isLoggedIn &&
                    navItems.map((item, i) => (
                      <Link
                        to={item.to}
                        className={`rounded-md px-3 py-2 text-base font-bold transition-all ${navStyles}`}
                      >
                        {item.name}
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            {isLoggedIn && (
              <div className="hidden lg:block">
                <div className="flex items-center ml-4 space-x-6 lg:ml-6">
                  <Menu>
                    <div class="relative ml-3">
                      <Menu.Button
                        className={`h-8 w-8 rounded-full bg-transparent ${navStyles}`}
                      >
                        {numNotif > 0 && <NotificationBadge num={numNotif} />}
                        <span class="sr-only">Open notification menu</span>
                        <i className="text-xl text-center fas fa-bell"></i>
                      </Menu.Button>
                      <Transition
                        enter="transition ease-out duration-200 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-50 w-64 mt-2 overflow-auto origin-top-right bg-white rounded-md shadow-lg h-80 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
                          <RadioGroup
                            className={"sticky top-0 bg-white p-3"}
                            value={notifType}
                            onChange={setNotifType}
                          >
                            <RadioGroup.Label className={"text-xl"}>
                              Notifications
                            </RadioGroup.Label>
                            <div className="flex items-center my-2 space-x-2 text-sm">
                              <RadioGroup.Option value="all">
                                {({ active, checked }) => (
                                  <span
                                    className={`cursor-pointer rounded-3xl px-2 py-1 ${
                                      checked
                                        ? "bg-green-500 bg-opacity-30 text-green-600"
                                        : "bg-gray-500 bg-opacity-10"
                                    } ${
                                      active ? "border-2 border-green-500" : ""
                                    }`}
                                  >
                                    All
                                  </span>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="unread">
                                {({ active, checked }) => (
                                  <span
                                    className={`cursor-pointer rounded-3xl px-2 py-1 ${
                                      checked
                                        ? "bg-green-500 bg-opacity-30 text-green-600"
                                        : "bg-gray-500 bg-opacity-10"
                                    } ${
                                      active ? "border-2 border-green-500" : ""
                                    }`}
                                  >
                                    Unread
                                  </span>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </RadioGroup>
                          {notifType === "all" && (
                            <div className="divide-y divide-solid divide-gray-50">
                              {notifArr && (
                                <div>
                                  {/* <!-- START OF NOTIFICATIONS --> */}
                                  {notifArr
                                    .sort((a, b) => b.time - a.time)
                                    .map(function (ntf) {
                                      return (
                                        <NotificationFactory paramNotif={ntf} />
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          )}
                          {notifType === "unread" && (
                            <div className="divide-y divide-solid divide-gray-50">
                              {notifArr && (
                                <div>
                                  {notifArr
                                    .sort((a, b) => b.time - a.time)
                                    .map(function (ntf) {
                                      return (
                                        <NotificationFactory paramNotif={ntf} />
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          )}              
                        </Menu.Items>
                      </Transition>
                    </div>
                  </Menu>
                  {/* <!-- Profile dropdown --> */}
                  <Menu>
                    <div class="relative ml-3">
                      <Menu.Button
                        className="rounded-full= flex max-w-xs items-center bg-transparent text-sm"
                        id="user-menu-button"
                      >
                        <span class="sr-only">Open user menu</span>
                        <img
                          className="object-cover w-8 h-8 rounded-full"
                          src={pfp}
                          alt=""
                        />
                      </Menu.Button>
                      <Transition
                        enter="transition ease-out duration-200 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items
                          className="absolute right-0 z-50 w-24 py-3 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="user-menu-button"
                          tabindex="-1"
                        >
                          {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/profile/${uid}`}
                                className={`mx-2 block rounded-md px-2 py-1  text-sm ${
                                  active
                                    ? "hover:bg-green-500 hover:text-white"
                                    : "text-gray-700"
                                } `}
                              >
                                Your Profile
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/"
                                onClick={() => {
                                  const auth = getAuth();
                                  signOut(auth).then(
                                    function () {
                                      // Sign-out successful.
                                    },
                                    function (error) {
                                      // An error happened.
                                    }
                                  );
                                }}
                                className={`mx-2 block rounded-md px-2 py-1  text-sm ${
                                  active
                                    ? "hover:bg-green-500 hover:text-white"
                                    : "text-gray-700"
                                } `}
                              >
                                Sign Out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </div>
                  </Menu>
                </div>
              </div>
            )}
            {!isLoggedIn && (
              <Link
                to="/login"
                className={`${navStyles} hidden rounded-md px-3 py-2 font-bold transition-all lg:block`}
              >
                Log in <i className="ml-1 fas fa-right-to-bracket"></i>
              </Link>
            )}
            <div class="-mr-2 flex lg:hidden">
              {/* <!-- Mobile menu button --> */}
              <button
                onClick={() => {
                  setNavbarOpen(!navbarOpen);
                }}
                type="button"
                className={`group relative inline-flex h-10 w-10 items-center justify-center rounded-md bg-transparent p-2 text-2xl ${
                  navbarOpen ? "hover:bg-green-500" : menuBgStyles
                }`}
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                {/* <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            --> */}
                {!navbarOpen ? (
                  <React.Fragment>
                    {numNotif > 0 && <NotificationBadge num={numNotif} />}
                    <i className={`${menuTextStyles} fas fa-bars`}></i>
                  </React.Fragment>
                ) : (
                  <i className="text-green-500 fas fa-xmark group-hover:text-white"></i>
                )}
              </button>
            </div>
          </div>
        </div>
        <Transition
          show={navbarOpen}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div class="lg:hidden" id="mobile-menu">
              <div class="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
                {isLoggedIn &&
                  navItems.map((item, i) => (
                    <Link
                      to={item.to}
                      className="block px-3 py-2 text-base font-medium text-gray-700 rounded-md hover:bg-green-500 hover:text-white"
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-50">
                {isLoggedIn && (
                  <div>
                    <Menu>
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img
                            class="h-10 w-10 rounded-full object-cover"
                            src={pfp}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium leading-none text-black">
                            {name}
                          </div>
                          <div className="text-sm font-medium leading-none text-gray-400">
                            {phoneNum}
                          </div>
                        </div>
                        <Menu.Button className="relative w-8 h-8 bg-transparent rounded-full hover:bg-black hover:bg-opacity-5">
                          {numNotif > 0 && <NotificationBadge num={numNotif} />}
                          <span class="sr-only">Open notification menu</span>
                          <i className="text-xl text-center text-gray-700 fas fa-bell"></i>
                        </Menu.Button>
                      </div>
                      <Transition
                        enter="transition ease-out duration-200 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items className="w-full mt-2 overflow-auto bg-white h-80 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500">
                          <RadioGroup
                            className={"sticky top-0 bg-white p-3"}
                            value={notifType}
                            onChange={setNotifType}
                          >
                            <RadioGroup.Label className={"text-xl"}>
                              Notifications
                            </RadioGroup.Label>
                            <div className="flex items-center my-2 space-x-2 text-sm">
                              <RadioGroup.Option value="all">
                                {({ active, checked }) => (
                                  <span
                                    className={`cursor-pointer rounded-3xl px-2 py-1 ${
                                      checked
                                        ? "bg-green-500 bg-opacity-30 text-green-600"
                                        : "bg-gray-500 bg-opacity-10"
                                    } ${
                                      active ? "border-2 border-green-500" : ""
                                    }`}
                                  >
                                    All
                                  </span>
                                )}
                              </RadioGroup.Option>
                              <RadioGroup.Option value="unread">
                                {({ active, checked }) => (
                                  <span
                                    className={`cursor-pointer rounded-3xl px-2 py-1 ${
                                      checked
                                        ? "bg-green-500 bg-opacity-30 text-green-600"
                                        : "bg-gray-500 bg-opacity-10"
                                    } ${
                                      active ? "border-2 border-green-500" : ""
                                    }`}
                                  >
                                    Unread
                                  </span>
                                )}
                              </RadioGroup.Option>
                            </div>
                          </RadioGroup>
                          {notifType === "all" && (
                            <div className="divide-y divide-solid divide-gray-50">
                              <div>
                                {notifArr.map(function (ntf) {
                                  return (
                                    <NotificationFactory paramNotif={ntf} />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                          {notifType === "unread" && (
                            <div className="divide-y divide-solid divide-gray-50">
                              <div>
                                {notifArr.map(function (ntf) {
                                  return (
                                    <NotificationFactory paramNotif={ntf} />
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    <div className="px-2 mt-3 space-y-1">
                      <Link
                        to={`/profile/${uid}`}
                        className="block px-3 py-2 text-base text-gray-700 transition-all rounded-md hover:bg-green-500 hover:text-white "
                      >
                        Your Profile
                      </Link>
                      <Link
                        to="/"
                        onClick={() => {
                          const auth = getAuth();
                          signOut(auth).then(
                            function () {
                              // Sign-out successful.
                            },
                            function (error) {
                              // An error happened.
                            }
                          );
                        }}
                        className="block px-3 py-2 text-base text-gray-700 transition-all rounded-md hover:bg-green-500 hover:text-white "
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                )}
                {!isLoggedIn && (
                  <div className="px-2">
                    <Link
                      to="/login"
                      className={`block rounded-md px-3 py-2 text-base text-gray-700 transition-all hover:bg-green-500 hover:text-white `}
                    >
                      Log in <i className="ml-1 fas fa-right-to-bracket"></i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </div>
  );
}
