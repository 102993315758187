import UserIcon from "components/UserIcon";

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export default function ReplyCard({ reply }) {
  return (
    <div className="w-full rounded-md bg-gray-50 bg-opacity-5 p-2 px-4 shadow-md">
      <div>
        <div className="grid grid-cols-2 items-center">
          <div className="flex items-center space-x-2">
            <div className="w-max">
              <UserIcon user={reply.user} />
            </div>
            <div className="md:text-lg">
              <span className="w-max">
                {reply.user.firstName + " " + reply.user.lastName}
              </span>
            </div>
          </div>
          <span className="text-right text-sm text-gray-200">
            {timeSince(new Date(reply.time))} ago
          </span>
        </div>
      </div>
      <p className="mt-2">{reply.text}</p>
    </div>
  );
}
