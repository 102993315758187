export default function DateItem({ date, onDelete }) {
  const dateString = date.toLocaleString("default", {
    dateStyle: "long",
  });
  const timeString = date.toLocaleString("default", {
    timeStyle: "short",
  });
  return (
    <div className="flex items-center justify-between w-full max-w-sm p-3 mx-auto text-white bg-green-500 rounded-md">
      <div className="flex items-center space-x-3">
        <span className="text-2xl">{dateString}</span>
        <span>{timeString}</span>
      </div>
      <button
        onClick={() => onDelete(date)}
        className="flex items-center w-8 h-8 p-1 mr-3 text-center transition-all rounded-full hover:bg-green-600"
      >
        <i className="mx-auto text-2xl fas fa-xmark"></i>
      </button>
    </div>
  );
}
