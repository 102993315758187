import React from "react";
import setDate from "scripts/setDate";
import {useParams, useNavigate} from "react-router-dom";
export default function PollResultItem({ date, onSelect }) {
  const navigate = useNavigate();
  const dateObj = new Date(date.date);
  const dateString = dateObj.toLocaleString("default", {
    dateStyle: "long",
  });
  const timeString = dateObj.toLocaleString("default", {
    timeStyle: "short",
  });
  let { id } = useParams();
  return (
    <div className="flex items-center mx-auto space-x-6">
      <button className="flex w-3/4" onClick={() => {
        //setDate(id, dateObj);
        if (window.confirm("You are selecting the final date for your event.\n Are you sure you want to set the final time?") === true) {
          setDate(id, dateObj)
          navigate(`/event/${id}`);
        }
        }}>
        <div
          className={`mx-auto flex w-full items-center justify-between rounded-md border-2 border-green-500 p-3 text-green-500 transition-all hover:bg-green-500 hover:text-white`}
        >
          <div className="flex items-center space-x-3">
            <span className="text-xl md:text-2xl">{dateString}</span>
            <span>{timeString}</span>
          </div>
        </div>
      </button>
      <h2 className="w-1/4 text-xl text-center md:text-2xl">
        {date.num} Votes
      </h2>
    </div>
  );
}
