import selectData from "./selectData";
import selectDataUser from "./selectDataUser";

import "@fortawesome/fontawesome-free/css/all.css";

// Import the functions you need from the SDKs you need
import { getDatabase, ref, set } from "firebase/database";

export default function addUserToEvent(eventID, userID) {
  //console.log("Event ID: " + eventID + " User ID: " + userID);
  //modify event to add user
  selectData(eventID).then(function (event) {
    event.members[event.members.length] = [userID, 0];
    set(ref(getDatabase(),"Events/" + eventID), event);
  });

  //modify user to add event
  selectDataUser(userID).then(function (user) {
    user.events[user.events.length] = eventID;
    set(ref(getDatabase(), "Users/" + userID), user);
  });
}