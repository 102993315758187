import { ref, set } from "firebase/database";
import selectData from "./selectData";
import { db } from "scripts/firebaseConfig";

export default function deletePost(id, PostID) {
  return new Promise(function (resolve, reject) {
    selectData(id).then(function (event) {
      event.posts.forEach(function (item, index, object) {
        if (item.id === PostID) {
          object.splice(index, 1);
          set(ref(db, "Events/" + event.id.toString()), event);
        }
      });
    });
    try {
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
}
