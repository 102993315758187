import Navbar from "@components/Navbar";
import ProgressButton from "components/ProgressButton";
import { useState } from "react";
import generateLink from "scripts/generateLink";
import { useParams } from "react-router-dom";
import Footer from "components/Footer";
import QRCode from "qrcode";
import { useEffect } from "react";
export default function Invite() {
  let { id } = useParams();
  const [showText, setShowText] = useState(false);
  const [srcQR, setSrcQR] = useState("");
  useEffect(() => {
    QRCode.toDataURL(`http://${window.location.host}/join/${id}`).then(
      setSrcQR
    );
  }, [id]);
  const textAppear = () => {
    setShowText(true);
    setTimeout(() => {
      setShowText(false);
    }, 2000);
  };
  return (
    <div className="flex h-screen flex-col justify-between">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <h1 className="mx-auto mt-4 w-max text-green-500">Invite Friends</h1>
        <p className="mx-auto w-5/6 text-center text-gray-500 sm:w-2/3 md:w-1/3 lg:text-lg">
          {" "}
          Invite your guests to this event.{" "}
        </p>
      </header>

      <main className="mb-auto flex h-full flex-col justify-between">
        <div className="my-10">
          <div className="mx-auto mb-2 flex h-48 w-48 items-center space-x-2 rounded-xl border-2 border-green-500 bg-green-500 px-3 py-2">
            <div className="mx-auto w-max rounded-md bg-white p-2">
              <img className="mx-auto h-36 w-36" src={srcQR} alt="" title="" />
            </div>
          </div>

          <div className="group mx-auto w-max items-center space-x-2 rounded-md border-2 border-green-500 px-3 py-2 text-2xl leading-none text-green-500 transition-all hover:bg-green-500 hover:text-white">
            <button
              onClick={() => {
                //TODO: change from localhost to live link
                generateLink(`http://${window.location.host}/join/${id}`);
                textAppear();
              }}
            >
              <span className="font-bold"> Share </span>
            </button>
          </div>
          <div
            className={`mx-auto my-1 w-max text-lg text-green-500 transition-all ${
              showText ? "opacity-100" : "opacity-0"
            }`}
          >
            Link copied!
          </div>
        </div>

        <div className="mx-auto mb-24 w-max">
          <ProgressButton enabled={true} to={`/event/${id}`} text="Done" />
        </div>
      </main>
      <Footer />
    </div>
  );
}
