import selectData from "./selectData";
import getUser from "./getUser";
import addNotification from "./addNotification";
import "@fortawesome/fontawesome-free/css/all.css";

// Import the functions you need from the SDKs you need
import { getDatabase, ref, set } from "firebase/database";

export default function changeEventLocation(eventID, newEventLocation) {
  selectData(eventID).then(function (event) {
    getUser().then(function (user) {
      event.members.forEach((member) => {
        //console.log("ADDING NOTIFICATION FROM " + userAuth.uid + " TO " + member[0] + "FROM EVENT: " + id);
        if (member[0] !== user.uid) {
          addNotification(
            user.uid,
            member[0],
            "location_change",
            eventID,
            "/event/" + eventID
          );
        }
      });
    });
    event.location = newEventLocation;
    set(ref(getDatabase(),"Events/" + eventID), event);
  });
}