import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React from "react";
import UserIcon from "@components/UserIcon";
import selectData from "scripts/selectData";
import { useNavigate, useParams } from "react-router-dom";
import deletePost from "scripts/deletePost";
import { Menu, Transition } from "@headlessui/react";
import getUser from "scripts/getUser";
import ReplyCard from "@components/ReplyCard";
import addReply from "scripts/addReply";
import selectDataUser from "scripts/selectDataUser";

export default function Post() {
  const [userID, setUserID] = React.useState();
  const [post, setPost] = React.useState({
    title: "Insert the title here",
    text: "This is the entirety of the post. there is a lot of text here. Thomas is stinky. Blah blah balha;;afjlhgjkjaefj fj kanfjn jenf an najef jenfjen gjeen gjs grjg aee j.",
    time: 1649712174642,
    link: "temp",
    user: {
      firstName: "Thomas",
      lastName: "Thurette",
      role: 2,
      link: "temp",
      img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
    },
  });

  const [replies, setReplies] = React.useState([
    {
      text: "This is my reply",
      time: 1650048080901,
      user: {
        firstName: "Thomas",
        lastName: "Thurette",
        role: 2,
        link: "temp",
        img: "https://cdn.discordapp.com/attachments/938831188464910436/958109855959384074/deafultPfp.png",
      },
    },
  ]);

  const [reply, setReply] = React.useState("");
  const handleChangeReply = (event) => {
    setReply(event.target.value);
  };

  let { id, PostID } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    getUser().then(function (user) {
      setUserID(user.uid);
    });
    selectData(id).then(function (event) {
      event.posts.forEach((element) => {
        if (element.id === Number.parseInt(PostID, 10)) {
          selectDataUser(element.user.id).then(function (user) {
            //console.log(user.id +'|'+element.user.id);
            //console.log(element.user.id);
            if (element.user.id !== null && element.user.id !== undefined) {
              element.user.img = user.img;
              element.user.firstName = user.firstName;
              element.user.lastName = user.lastName;
            }
            setPost(element);
          });
          element.replies.slice(1).forEach((reply) => {
            selectDataUser(reply.user.id).then(function (user) {
              reply.user.firstName = user.firstName;
              reply.user.lastName = user.lastName;
              reply.user.img = user.img;
            });
          });
          setTimeout(function () {
            setReplies(element.replies.slice(1).reverse());
          }, 100);
        }
      });
    });
  }, [id, PostID]);

  const updateReplies = () => {
    selectData(id).then(function (tmp) {
      tmp.posts.forEach((element) => {
        if (element.id === Number.parseInt(PostID, 10)) {
          selectDataUser(element.user.id).then(function (user) {
            if (element.user.id !== null && element.user.id !== undefined) {
              element.user.img = user.img;
              element.user.firstName = user.firstName;
              element.user.lastName = user.lastName;
            }
            setPost(element);
          });
          element.replies.slice(1).forEach((reply) => {
            selectDataUser(reply.user.id).then(function (user) {
              reply.user.img = user.img;
            });
          });
          setTimeout(function () {
            setReplies(element.replies.slice(1).reverse());
          }, 100);
        }
      });
    });
  };

  return (
    <div className="flex flex-col justify-between h-screen">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
          ]}
        />
        <div className="grid items-center max-w-xl grid-cols-3 mx-auto mt-4">
          <div className="mx-auto w-max">
            <BackButton />
          </div>
          <h1 className="mx-auto leading-none text-green-500 w-max">
            {post.title}
          </h1>
          <div className="relative mx-auto w-max">
            {userID === post.user.id && (
              <Menu>
                <Menu.Button className="w-6 h-6">
                  <i className="text-xl text-gray-700 fas fa-ellipsis"></i>
                </Menu.Button>
                <Transition
                  enter="transition ease-out duration-200 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Menu.Items
                    className="absolute right-0 z-50 px-2 py-3 mt-2 origin-top-right bg-white rounded-md shadow-lg w-36 ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                  >
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-green-500 hover:text-white"
                              : "text-gray-700"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {}}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          className={`block w-full rounded-md px-2 py-1 text-sm ${
                            active
                              ? "hover:bg-green-500 hover:text-white"
                              : "text-gray-700"
                          } `}
                        >
                          <button
                            className="w-full text-left"
                            onClick={() => {
                              deletePost(id, Number.parseInt(PostID, 10)).then(
                                (r) => {
                                  if (r) {
                                    navigate(`/event/${id}`, { replace: true });
                                  }
                                }
                              );
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
      </header>
      <main className="px-3 mb-auto">
        <div className="max-w-lg mx-auto my-4">
          <div className="flex items-center space-x-3">
            <UserIcon user={post.user} />
            <span className="text-lg">
              {post.user.firstName} {post.user.lastName}
            </span>
          </div>
          <div>
            <p>{post.text}</p>
            <span className="text-gray-200">
              {new Date(post.time).toLocaleString("default", {
                dateStyle: "medium",
                timeStyle: "short",
              })}
            </span>
          </div>
          <div className="mt-2">
            <span className="font-bold">Replies</span>
            <div className="flex flex-col w-full max-w-xl mx-auto my-8 space-y-3 overflow-y-auto h-96 px-7 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-green-500 lg:px-0">
              {replies.map((reply) => (
                <ReplyCard reply={reply} />
              ))}
            </div>
          </div>
          <div className="flex items-center p-4 space-x-2 bg-black rounded-full bg-opacity-5">
            <textarea
              class="
              form-control
              m-0
              w-full
              resize-none
              rounded-md bg-gray-50 bg-opacity-5
              bg-clip-padding px-3 py-1.5
              text-base
              font-normal
              text-gray-700
              transition ease-in-out focus:bg-white focus:outline-none"
              value={reply}
              onChange={handleChangeReply}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  //Do something
                }
              }}
              id="newReply"
              rows="1"
              placeholder="Write a reply here"
            ></textarea>
            <button
              onClick={() => {
                if (reply !== "") {
                  getUser().then(function (userAuth) {
                    selectDataUser(userAuth.uid).then(function (user) {
                      addReply(id, PostID, { reply }.reply, {
                        id: user.id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        link: `/profile/${user.id}`,
                        img: user.img,
                      }).then((r) => {
                        if (r) {
                          updateReplies();
                          setReply("");
                        }
                      });
                    });
                  });
                }
              }}
            >
              <div className="flex items-center p-2 mx-auto bg-green-500 rounded-full justify-right h-14 w-14">
                <i className="mx-auto text-xl text-white fas fa-paper-plane"></i>
              </div>
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
