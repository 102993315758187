import Navbar from "@components/Navbar";
import Footer from "@components/Footer";
import BackButton from "@components/BackButton";
import React from "react";
import selectData from "scripts/selectData";
import { useNavigate, useParams } from "react-router-dom";
import ProgressButton from "components/ProgressButton";
import changeEvent from "scripts/changeEvent";
import TextField from "@mui/material/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Box from "@mui/material/Box";

export default function EditEventDriver() {
  const [newTitle, setNewTitle] = React.useState();
  const [newDescription, setNewDescription] = React.useState();
  const [address, setAddress] = React.useState("");
  const navigate = useNavigate();
  const handleChange3 = (address) => {
    setAddress(address);
  };
  const handleSelect = (_address) => {
    geocodeByAddress(_address)
      .then((results) => setAddress(results[0].formatted_address))
      .catch((error) => console.error("Error", error));
  };

  let { id } = useParams();

  React.useEffect(() => {
    selectData(id).then(function (event) {
      if (event.id === Number.parseInt(id, 10)) {
        setNewTitle(event.title);
        setAddress(event.location);
        setNewDescription(event.description);
      }
    });
  }, [id]);
  return (
    <div className="flex h-screen flex-col justify-between">
      <header>
        <Navbar
          variant="green"
          navItems={[
            { name: "Create Event", to: "/create" },
            { name: "My Events", to: "/my-events" },
            { name: "About", to: "/about" },
          ]}
        />
        <div className="mx-auto mt-4 flex w-3/5 items-center justify-evenly">
          <BackButton />
          <h1 className="mx-auto w-max text-green-500">Change Event Details</h1>
          <div className="invisible"></div>
        </div>
      </header>
      <main className="mb-auto">
        <p className="mx-auto w-max">Specify the new details of your event</p>
        <div className="mx-auto w-max">
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiTextField-root": { width: "25ch" },
            }}
          >
            <TextField
              required
              id="standard-required"
              value={newTitle}
              label="New Title"
              variant="standard"
              margin="normal"
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
              sx={{
                "& label.Mui-focused": {
                  color: "#13BB7D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#13BB7D",
                },
                "& .MuiInputLabel-root": {
                  color: "#13BB7D",
                  fontSize: "1.125rem",
                  fontFamily: "Lato",
                  lineHeight: "1.75rem",
                },
              }}
            />

            <TextField
              value={newDescription}
              label="New Description"
              variant="standard"
              margin="normal"
              onChange={(e) => {
                setNewDescription(e.target.value);
              }}
              sx={{
                "& label.Mui-focused": {
                  color: "#13BB7D",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#13BB7D",
                },
                "& .MuiInputLabel-root": {
                  color: "#13BB7D",
                  fontSize: "1.125rem",
                  fontFamily: "Lato",
                  lineHeight: "1.75rem",
                },
              }}
            />

            <span className="text-lg text-green-500">New Location</span>
            <PlacesAutocomplete
              value={address}
              onChange={handleChange3}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <input
                    {...getInputProps({
                      placeholder: "Search Places ...",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Box>

          <div className="mx-auto w-max">
            <ProgressButton
              onClick={() => {
                changeEvent(
                  id,
                  { newTitle }.newTitle,
                  { address }.address,
                  { newDescription }.newDescription
                ).then((r) =>{
                    if(r){
                        navigate(`/event/${id}`);
                    }
                })
              }}
              enabled={newTitle !== (null || "")}
              to={""}
              text="Done"
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
